import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Auth from "../../Redux/Actions/Auth";
import ApiAuth from "../../API/ApiAuth";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import {GoogleView} from "../../Helper/GoogleView";
import {Helmet} from 'react-helmet'
import {getPageTitle} from '../../Redux/variables'


const EmailVerification = (props) => {
    const dispatch = useDispatch()
    const {code,email} = useParams()
    const [loader,setLoader] = useState(true)
    useEffect(()=>{
        GoogleView('Email Verification',`/email-verification/${code}/${email}`)
    },[])
    useEffect(async ()=>{
        console.log(code,email,'code')

        if (code === undefined && code === '' && code === null){
            toast.error('Url is not valid')
            props.history.push('/')
            return;
        }
        if (email === undefined && email === '' && email === null){
            toast.error('Url is not valid')
            props.history.push('/')
            return;
        }
        dispatch(Auth.loader(true))
        await ApiAuth.registerEmailVerification({
            email:email,
            code:code
        }).then((response)=> {

            const {success,message,data} = response
            if (success === true) {
                toast.success(message)
                props.history.push('/')

            } else {
                toast.error(message)
                props.history.push('/')
            }
        }).catch((error)=>{
            toast.error(error)
            dispatch(Auth.loader(false))
        })


    },[])
    return (
        <>
            <Helmet>
                <title>Email Verification {getPageTitle()}</title>
                <meta name="description" content="" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Home | PropSure" />
                <meta property="og:description" content="" />
                <meta property="og:url" content="https://www.propsure.com.pk/" />
                <meta property="og:site_name" content="PropSure" />
                <meta property="article:publisher" content="" />
                <meta property="og:image" content="" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Header state={props} />
            <section style={{height:'800px'}} className="second-section grey-color-1" id="Services-section">
                <div className="container services">
                    <div className="row">
                        <div className="col-lg-12">
                            {
                                loader && (<div className={'alert alert-success text-center mt-5'}>Please wait we are verifying your email address...</div>)
                            }
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default EmailVerification