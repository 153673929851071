import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faMapMarkerAlt, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";

import {
  faFacebookF,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import React from "react";
import "./styles.css";
import {Link, useLocation} from "react-router-dom";

const Footer = (props) => {
  const {pathname} = useLocation()
  return (
    <section className="sixth-section" id="contact-us">
      <div className="bg-red-line1">
        <div className="bg-mws-color footer-color" >
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="d-flex">
                  <div className="flex-shrink-0 px-0 logo-center">
                    <img
                        style={{width:'218px'}}
                        className="img-fluid"
                        src={require("../../assets/img/logo-white.svg").default}
                        alt=""
                    />
                  </div>
                </div>
                {/* <div className="d-flex">
              <div className="flex-shrink-0">
                <FontAwesomeIcon
                  size="2x"
                  icon={faMapMarkerAlt}
                  color="white"
                />
              </div>
              <div className="flex-grow-1 ms-3">
                <h5>Head Office</h5>
                <p>4th Floor, Beverly Centre, Blue Area, Islamabad</p>
              </div>
            </div> */}
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    {/* <i className="	fa fa-map-marker" /> */}
                    <FontAwesomeIcon
                        size="2x"
                        icon={faMapMarkerAlt}
                        color="white"
                    />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h5>Islamabad Office</h5>
                    <p>
                      CDA Secretariat, Block-V, Sector G-7/4, Islamabad
                    </p>
                    <FontAwesomeIcon
                        style={{ marginRight: "1%" }}
                        size="1x"
                        icon={faPhoneAlt}
                        color="white"
                    />
                    <span className="p-0 text-light">+92-51-9252946</span>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    {/* <i className="	fa fa-map-marker" /> */}
                  {/*  <FontAwesomeIcon
                        size="2x"
                        icon={faMapMarkerAlt}
                        color="white"
                    />*/}
                  </div>
                 {/* <div className="flex-grow-1 ms-3">
                    <h5>Karachi Office</h5>
                    <p>
                      Office No. B-302, 3rd Floor, Fortune Tower, Main
                      Shahrah-e-Faisal, Karachi.
                    </p>
                    <FontAwesomeIcon
                        style={{ marginRight: "1%" }}
                        size="1x"
                        icon={faPhoneAlt}
                        color="white"
                    />
                    <span className="p-0 text-light">021-34541403</span>
                  </div>*/}
                </div>
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    {/* <i className="	fa fa-map-marker" /> */}
                   {/* <FontAwesomeIcon
                        size="2x"
                        icon={faMapMarkerAlt}
                        color="white"
                    />*/}
                  </div>
                 {/* <div className="flex-grow-1 ms-3">
                    <h5>Lahore Office</h5>
                    <p className="pb-0">
                      1st Floor, 9 Arches Arcade, 76-B1, M.M. Alam Road,
                      Gulberg-III, Lahore
                    </p>
                    <FontAwesomeIcon
                        style={{ marginRight: "1%" }}
                        size="1x"
                        icon={faPhoneAlt}
                        color="white"
                    />
                    <span className="p-0 text-light">042-36407379</span>
                  </div>*/}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div hidden={true} className="contact-icons">
                  <a
                      href="https://twitter.com/PropsureDigital?t=ZNWYzqdPQMGN91OG3VLvpA&s=08"
                      target="_blank"
                  >
                    <FontAwesomeIcon
                        style={{ marginRight: "4%" }}
                        size="2x"
                        icon={faTwitter}
                        color="white"
                    />
                  </a>
                  <a
                      href="https://instagram.com/propsuredigital?utm_medium=copy_link"
                      target="_blank"
                  >
                    <FontAwesomeIcon
                        style={{ marginRight: "4%" }}
                        size="2x"
                        icon={faInstagram}
                        color="white"
                    />
                  </a>
                  <a
                      href="https://www.facebook.com/PropSureDigital/"
                      target="_blank"
                  >
                    <FontAwesomeIcon
                        style={{ marginRight: "4%" }}
                        size="2x"
                        icon={faFacebookF}
                        color="white"
                    />
                  </a>
                </div>
                <div className="contact" style={{ float: "right",marginTop:'20px' }}>
                  <p>
                    Contact us at &nbsp;
                    <b style={{ fontWeight: "bold" }}><a style={{color:'inherit',textDecoration:'none'}} href="mailto:dir.staff@cda.gov.pk"> info@cda.gov.pk</a></b>
                    <br />
                    to get in touch with us.
                  </p>
                  <div style={{textAlign:'left'}}>
                    <h5>Helpline</h5>
                    <ul style={{
                      padding:'0px',
                      margin:'0px',
                      listStyle:'none',
                      marginBottom:'10px',
                      fontFamily: 'Poppins',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      color:'#fff',
                      fontSize: '22px'}} className={'list-numbers'}>
                      <li><a style={{color:'inherit',textDecoration:'none'}} href="tel:+92519252946"><img style={{width:'17px',marginTop:'-3px',marginRight:'10px'}} src={require("../../assets/img/phone-icon.svg").default}
                                                                                                        alt=""/> +92-51-9252946</a></li>
                    </ul>
                  </div>
                 {/* <p>
                    <Link className="text-light" to="/terms-and-conditions">
                      Terms &amp; Conditions
                    </Link>
                  </p>
                  <p>
                    <Link className="text-light" to="/privacy-policies">
                      Privacy Policies
                    </Link>
                  </p>*/}
                  {/* <p>
                <FontAwesomeIcon
                  style={{ marginRight: "1%" }}
                  size="1x"
                  icon={faPhoneAlt}
                  color="white"
                />
                051-8442244 <br />
                <FontAwesomeIcon
                  style={{ marginRight: "1%" }}
                  size="1x"
                  icon={faPhoneAlt}
                  color="white"
                />
                021-34541403 <br />
                <FontAwesomeIcon
                  style={{ marginRight: "1%" }}
                  size="1x"
                  icon={faPhoneAlt}
                  color="white"
                />
                042-36407379
              </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
