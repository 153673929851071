import { useEffect } from "react";
import { isMobile } from "../../../utils";
import GetFeatureInfo from "./GetFeatureInfo";
import {useDispatch} from "react-redux";

const WMSData = (props) => {
    const dispatch = useDispatch()
  useEffect(() => {
    {
      isMobile()
        ? props.setState({
            bottom: true,
          })
        : props.setState({
            left: true,
          });
    }
    GetFeatureInfo(props.centroid, props.map, props.setplotData,dispatch);
  }, [props.centroid]);

  return null;
};

export default WMSData;
