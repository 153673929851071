import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
  Menu,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { useTranslation } from "react-i18next";
import {
  AboutIslamabadRoutes,
  AboutusRoutes,
  EServicesRoutes,
  LegislationRoutes,
  dataReaserchRoutes,
  mediaCenterRoutes,
  publicNoticesRoutes,
  procurementRoutes,
  wingDirectoratesRoutes,
} from "../../utils/constant.js";

import "./index.css";
import { MenuItem } from "@material-ui/core";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import { CDA_BASE_URL } from "../../config.js";

const NavBar = () => {
  const [about, setAbout] = useState(null);
  const [wings, setWings] = useState(null);
  const [eservices, setEservices] = useState(null);
  const [media, setMedia] = useState(null);
  const [legislation, setLegislation] = useState(null);
  const [dataResearch, setDataResearch] = useState(null);
  const [aboutIsb, setAboutIsb] = useState(null);

  const [publicNotices, setPublicNotices] = useState(null);
  const [procurement, setProcurement] = useState(null);

  const handleClick = (e, type) => {
    if (e.target.value === "about" || type === "about") {
      setAbout(e.currentTarget);
    }
    if (e.target.value === "wings" || type === "wings") {
      setWings(e.currentTarget);
    }
    if (e.target.value === "e-services" || type === "e-services") {
      setEservices(e.currentTarget);
    }
    if (e.target.value === "media-center" || type === "media-center") {
      setMedia(e.currentTarget);
    }
    if (e.target.value === "legislation" || type === "legislation") {
      setLegislation(e.currentTarget);
    }
    if (e.target.value === "data" || type === "data") {
      setDataResearch(e.currentTarget);
    }
    if (e.target.value === "about-isb" || type === "about-isb") {
      setAboutIsb(e.currentTarget);
    }
  };
  const handleClose = (type) => {
    if (type === "about") {
      setAbout(null);
    }
    if (type === "wings") {
      setWings(null);
    }
    if (type === "e-services") {
      setEservices(null);
    }
    if (type === "media-center") {
      setMedia(null);
    }
    if (type === "legislation") {
      setLegislation(null);
    }
    if (type === "data") {
      setDataResearch(null);
    }
    if (type === "about-isb") {
      setAboutIsb(null);
    }
  };

  const handleSubMenuClick = (e, type) => {
    if (e.target.value === "public" || type === "public") {
      setPublicNotices(e.currentTarget);
    }
    if (e.target.value === "procurement" || type === "procurement") {
      setProcurement(e.currentTarget);
    }
  };
  const handleCloseSubMenu = (type) => {
    if (type === "public") {
      setPublicNotices(null);
    }
    if (type === "procurement") {
      setProcurement(null);
    }
  };
  return (
    <AppBar position="sticky" className="hideOnMobile">
      <div style={{ backgroundColor: "#739B21", padding: "16px 145px" }}>
        <div
          style={{
            margin: "0 auto",
            display: "flex",
            maxWidth: "1440px",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* About Routes */}
          <div>
            <Button
              value="about"
              onClick={(e) => handleClick(e)}
              style={{ color: "white" }}
              endIcon={
                <KeyboardArrowDownOutlinedIcon
                  onClick={(e) => handleClick(e, "about")}
                />
              }
              sx={{ textTransform: "none" }}
              className={`${about ? "menus menusOpen" : "menus"}`}
            >
              About Us
            </Button>
            <Menu
              anchorEl={about}
              open={about}
              onClose={() => handleClose("about")}
              sx={{ top: "16px" }}
            >
              {AboutusRoutes.map((item) => (
                <a
                  style={{
                    textDecoration: "none",
                    color: "#37474F",
                    fontSize: "12px",
                  }}
                  className="menu"
                  href={`${CDA_BASE_URL}${item.link}`}
                >
                  <MenuItem>{item.name}</MenuItem>
                </a>
              ))}
            </Menu>
          </div>
          {/* Wings & Directortates Routes */}
          <div>
            <Button
              value="wings"
              className={`${wings ? "menus menusOpen" : "menus"}`}
              style={{ color: "white" }}
              onClick={(e) => handleClick(e)}
              endIcon={
                <KeyboardArrowDownOutlinedIcon
                  onClick={(e) => handleClick(e, "wings")}
                />
              }
              sx={{ textTransform: "none" }}
            >
              Wings & Directorates
            </Button>
            <Menu
              anchorEl={wings}
              open={wings}
              onClose={(e) => handleClose("wings")}
              sx={{ top: "16px" }}
            >
              {wingDirectoratesRoutes.map((item) => (
                <a
                  style={{
                    textDecoration: "none",
                    color: "#37474F",
                    fontSize: "12px",
                  }}
                  className="menu"
                  href={`${CDA_BASE_URL}${item.link}`}
                >
                  <MenuItem>{item.name}</MenuItem>
                </a>
              ))}
            </Menu>
          </div>

          {/* E-services Rotues */}
          <div>
            <Button
              style={{ color: "white" }}
              className={`${eservices ? "menus menusOpen" : "menus"}`}
              value="e-services"
              onClick={(e) => handleClick(e)}
              endIcon={
                <KeyboardArrowDownOutlinedIcon
                  onClick={(e) => handleClick(e, "e-services")}
                />
              }
              sx={{ textTransform: "none" }}
            >
              E-Services
            </Button>
            <Menu
              anchorEl={eservices}
              open={eservices}
              onClose={(e) => handleClose("e-services")}
              sx={{ top: "16px" }}
            >
              {EServicesRoutes.map((item) => (
                <a
                  style={{
                    textDecoration: "none",
                    color: "#37474F",
                    fontSize: "12px",
                  }}
                  className="menu"
                  href={item.link}
                >
                  <MenuItem>{item.name}</MenuItem>
                </a>
              ))}
            </Menu>
          </div>

          {/* Media-center Rotues */}
          <div>
            <Button
              className={`${media ? "menus menusOpen" : "menus"}`}
              value="media-center"
              style={{ color: "white" }}
              onClick={(e) => handleClick(e)}
              sx={{ textTransform: "none" }}
              endIcon={
                <KeyboardArrowDownOutlinedIcon
                  onClick={(e) => handleClick(e, "media-center")}
                />
              }
            >
              Media Center
            </Button>
            <Menu
              anchorEl={media}
              open={media}
              onClose={(e) => handleClose("media-center")}
              sx={{ top: "16px" }}
            >
              {mediaCenterRoutes.map((item) => {
                if (item.name === "Public Notices") {
                  return (
                    <div>
                      <Button
                        value="public"
                        style={{ color: "#37474F" }}
                        sx={{
                          textTransform: "none",
                          fontSize: "12px",
                          width: "146px",
                          gap: "8px",
                          "&:hover": { background: "#F2F2F2" },
                        }}
                        onClick={(e) => handleSubMenuClick(e)}
                        endIcon={
                          <KeyboardArrowRightOutlinedIcon
                            onClick={(e) => handleSubMenuClick(e, "public")}
                          />
                        }
                      >
                        {item.name}
                      </Button>
                      <Menu
                        anchorEl={publicNotices}
                        open={publicNotices}
                        onClose={(e) => handleCloseSubMenu("public")}
                        sx={{ top: "0px", left: "35px" }}
                      >
                        {publicNoticesRoutes.map((item) => (
                          <a
                            style={{
                              textDecoration: "none",
                              color: "#37474F",
                              fontSize: "12px",
                            }}
                            className="menu"
                            href={`${CDA_BASE_URL}${item.link}`}
                          >
                            <MenuItem>{item.name}</MenuItem>
                          </a>
                        ))}
                      </Menu>
                    </div>
                  );
                }
                if (item.name === "Procurement") {
                  return (
                    <div>
                      <Button
                        value="procurement"
                        onClick={(e) => handleSubMenuClick(e)}
                        style={{ color: "#37474F" }}
                        sx={{
                          textTransform: "none",
                          fontSize: "12px",
                          width: "146px",
                          gap: "18px",
                          "&:hover": { background: "#F2F2F2" },
                        }}
                        endIcon={
                          <KeyboardArrowRightOutlinedIcon
                            onClick={(e) =>
                              handleSubMenuClick(e, "procurement")
                            }
                          />
                        }
                      >
                        {item.name}
                      </Button>
                      <Menu
                        anchorEl={procurement}
                        open={procurement}
                        onClose={(e) => handleCloseSubMenu("procurement")}
                        sx={{ top: "-15px", left: "15px" }}
                      >
                        {procurementRoutes?.map((item) => (
                          <a
                            style={{
                              textDecoration: "none",
                              color: "#37474F",
                              fontSize: "12px",
                            }}
                            className="menu"
                            href={`${CDA_BASE_URL}${item.link}`}
                          >
                            <MenuItem>{item.name}</MenuItem>
                          </a>
                        ))}
                      </Menu>
                    </div>
                  );
                }
                return (
                  <a
                    style={{
                      textDecoration: "none",
                      color: "#37474F",
                      fontSize: "12px",
                    }}
                    className="menu"
                    href={`${CDA_BASE_URL}${item.link}`}
                  >
                    <MenuItem>{item.name}</MenuItem>
                  </a>
                );
              })}
            </Menu>
          </div>

          {/* Legislation Rotues */}
          <div>
            <Button
              value="legislation"
              className={`${legislation ? "menus menusOpen" : "menus"}`}
              style={{ color: "white" }}
              onClick={(e) => handleClick(e)}
              endIcon={
                <KeyboardArrowDownOutlinedIcon
                  onClick={(e) => handleClick(e, "legislation")}
                />
              }
              sx={{ textTransform: "none" }}
            >
              Legislation
            </Button>
            <Menu
              anchorEl={legislation}
              open={legislation}
              onClose={(e) => handleClose("legislation")}
              sx={{ top: "16px" }}
            >
              {LegislationRoutes?.map((item) => (
                <a
                  style={{
                    textDecoration: "none",
                    color: "#37474F",
                    fontSize: "12px",
                  }}
                  className="menu"
                  href={`${CDA_BASE_URL}${item.link}`}
                >
                  <MenuItem>{item.name}</MenuItem>
                </a>
              ))}
            </Menu>
          </div>

          {/* Data & Research Rotues */}
          <div>
            <Button
              value="data"
              className={`${dataResearch ? "menus menusOpen" : "menus"}`}
              style={{ color: "white" }}
              onClick={(e) => handleClick(e)}
              endIcon={
                <KeyboardArrowDownOutlinedIcon
                  onClick={(e) => handleClick(e, "data")}
                />
              }
              sx={{ textTransform: "none" }}
            >
              Data & Research
            </Button>
            <Menu
              anchorEl={dataResearch}
              open={dataResearch}
              onClose={(e) => handleClose("data")}
              sx={{ top: "16px" }}
            >
              {dataReaserchRoutes?.map((item) => (
                <a
                  style={{
                    textDecoration: "none",
                    color: "#37474F",
                    fontSize: "12px",
                  }}
                  className="menu"
                  href={
                    item.name === "OPVS" ? "#" : `${CDA_BASE_URL}${item.link}`
                  }
                >
                  <MenuItem>{item.name}</MenuItem>
                </a>
              ))}
            </Menu>
          </div>

          {/* About Islamabad Rotues */}
          <div>
            <Button
              value="about-isb"
              className={`${aboutIsb ? "menus menusOpen" : "menus"}`}
              style={{ color: "white" }}
              onClick={(e) => handleClick(e)}
              endIcon={
                <KeyboardArrowDownOutlinedIcon
                  onClick={(e) => handleClick(e, "about-isb")}
                />
              }
              sx={{ textTransform: "none" }}
            >
              About Islamabad
            </Button>
            <Menu
              anchorEl={aboutIsb}
              open={aboutIsb}
              onClose={(e) => handleClose("about-isb")}
              sx={{ top: "16px" }}
            >
              {AboutIslamabadRoutes?.map((item) => (
                <a
                  style={{
                    textDecoration: "none",
                    color: "#37474F",
                    fontSize: "12px",
                  }}
                  className="menu"
                  href={
                    item.name === "OPVS" ? "#" : `${CDA_BASE_URL}${item.link}`
                  }
                >
                  <MenuItem>{item.name}</MenuItem>
                </a>
              ))}
            </Menu>
          </div>
        </div>
      </div>
    </AppBar>
  );
};

export default NavBar;
