import {
    AUTH_LOGIN,
    AUTH_LOGIN_ERROR, AUTH_LOGOUT,
    getLoginUser,
    getUserToken, removeTokenUser,
    SET_LOADER,
    setLocalUser,
    setToken, UPDATE_AUTH_USER
} from "../variables";
import ApiAuth from "../../API/ApiAuth";

const initialState = {
    user: getLoginUser() ?? {},
    token:getUserToken() ?? '',
    loader:false

};

const AuthReducer =   (state = initialState, action) => {
    switch (action.type)
    {
        case SET_LOADER:
            return {
                ...state,
                loader: action.payload
            }
        case UPDATE_AUTH_USER :
            setLocalUser(action.payload)
            return  {
                ...state,
                user:action.payload
            }

        case AUTH_LOGOUT :
            console.log('logout hit',action)
            removeTokenUser()
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            return {
                ...state,
                user: {},
                token:''
            }

        case AUTH_LOGIN:
            const {payload} = action
            const {token,user} = payload
            setLocalUser(user)
            setToken(token)
            return {
                ...state,
                user,
                token,
                loader:false
            }
        case AUTH_LOGIN_ERROR:

            return {
                ...state
            }

        default:
            return state;
    }
}

export default AuthReducer