import React from "react";
import {CDA_BASE_URL} from '../config';

const LoginButton = (props) =>{
    return (
        <>
            <div className="mt-3 d-grid text-center">
                 <p style={{ marginBottom: 0,textAlign:"center" }}>Already have an account then please
            <a
            onClick={() => {
                props.history.push('/')
            }}
                  style={{
                    marginBottom: 0,
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: "16.1px",
                    fontWeight: "400",
                    color: "#739B21",
                    textDecoration: "none",
                    paddingLeft:"5px",
                    paddingRight:"5px",
                    cursor: 'pointer'
                  }}
                //   href= {`${CDA_BASE_URL}`}
                >
                  login 
                </a>
                here
                </p> 


            
                {/* <button
                    type={'button'}
                    onClick={() => {
                        props.history.push('/')
                    }}
                    className={'text-color-green'}
                    style={{
                        textDecoration: "none",
                        cursor: "pointer",
                        border:'none',
                        background:'none'
                    }}
                >
                    Login
                </button> */}
            </div>
        </>
    )
}

export default LoginButton;