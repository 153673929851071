import React, { useEffect } from "react";
import { useState } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { nodeProd } from "../../../../config";
import Axios from "axios";
import { Link } from "react-router-dom";
import { isMobile } from "../../../../utils";
import {useDispatch} from "react-redux";
import ApiAuth from "../../../../API/ApiAuth";
import swal from "sweetalert";
import {toast} from "react-toastify";
import GetFeatureInfo from "../GetFeatureInfo";

function ElasticSearch(props) {

  const dispatch = useDispatch()
  const [items, setItems] = useState([
    {
      id: 0,
      name: "No results found",
    },
  ]);
  const [housingSchemes,setHousingSchemes] = useState([])
  const [plots,setPlots] = useState([])
  const [selectedScheme, setSelectedScheme] = useState({})
  const handleOnSearch = async (string, results) => {


    await ApiAuth.plotHousingSearch(string).then((response) =>{
      const {success,data} = response
      if (success === true ) {
        /*console.log('testt data', data[0])*/
        setHousingSchemes(data)
      }

    }).catch((error) => {
     /* itemArr.push({
        id: 0,
        name: "please wait...",
      });
      setItems(itemArr);
      toast.error(error)*/
    })
    return;
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    let itemArr = []
    await ApiAuth.getAllHousingSchemeList({param:string}).then((response) =>{
      const {success,data,message} = response
      if (success === true ) {
        let hits = data;
       /* return;
        let {hits} = data.hits;*/
        if (hits) {
          itemArr = hits.map((obj, i) => {
            return{
              id: i,
              name: obj.name,
              centroid: obj.centroid,
              zoom: obj.zoom,
            }
          });
          setItems(itemArr);
        } else {
          itemArr.push({
            id: 0,
            name: "No results found",
          });
          setItems(itemArr);
        }
      }

    }).catch((error) => {
      itemArr.push({
        id: 0,
        name: "please wait...",
      });
      setItems(itemArr);
      toast.error(error)
    })

    /*Axios.get(`${nodeProd}search?param=${string}`, {
      headers: {
        authToken: `${props.user.accessToken}`,
      },
    })
      .then(function (response) {
        let data = response.data.hits.hits;

        if (data) {
          data.map((obj, i) => {
            itemArr.push({
              id: i,
              name: obj._source.address,
              centroid: obj._source.centroid,
              zoom: obj._source.zoom,
            });
          });
        } else {
          itemArr.push({
            id: 0,
            name: "No results found",
          });
        }

        setItems(itemArr);
      })
      .catch(function (error) {
        console.log(error);
        itemArr.push({
          id: 0,
          name: "please wait...",
        });
      });*/
  };

  const handleFormPlotSearch = (search) =>{
    ApiAuth.plotSearchByHousingScheme(selectedScheme.id,search).then((results)=>{
      const {success,data} = results
      if (success){
        setPlots(data)
      }
    })
  }
  const handleOnSelectPlotSearch = (item)=>{

    const {id,name} = item;
    if (id){
      try
      {
        let LatLng = {
          lng: parseFloat(item.centeriod.split(",")[1]),
          lat: parseFloat(item.centeriod.split(",")[0]),
        };


        props.setcentroid(LatLng);

        props.map.setView(LatLng, 19);

        GetFeatureInfo(LatLng, props.map, props.setplotData,dispatch);
        setSelectedScheme(()=>({}))
      }
      catch (e) {
        toast.error(e.message)
      }


    }
  }

  const handleOnHover = (result) => {
    // the item hovered
  /*  console.log(result);*/
  };
  const handleonPlotSearh = async (item)=>{
   /* console.log('testtt handleOnSearch', item)*/
    await ApiAuth.plotSearch(item).then((response)=>{


    })
  }

  const handleOnSelect = async (item) => {
    const {id,name} = item

    setSelectedScheme({id,name})
    return
      // try
      // {
      //   let LatLng = {
      //     lat: parseFloat(item.centroid.split(",")[1]),
      //     lng: parseFloat(item.centroid.split(",")[0]),
      //   };
  
      //   props.setcentroid(LatLng);
  
      //   props.map.setView(LatLng, item.zoom);
      // }
      // catch (e) {
      //   toast.error(e.message)
      // }
  
      // GetFeatureInfo(LatLng, props.map, props.setplotData);
  


      let itemArr = []
     await ApiAuth.plotHousingSearch(id).then((response) =>{
        const {success,data} = response
        if (success === true ) {
         // console.log('testt data', data[0])

          setPlots(data)
         // setSelectedScheme('888')
        
        }
  
      }).catch((error) => {
        /*itemArr.push({
          id: 0,
          name: "please wait...",
        });
        setItems(itemArr);*/
        toast.error(error)
      })
  };

  const handleOnFocus = () => {
    console.log("Focused");
  };

  const getHousingSchemeData = async () => {
    const response = await ApiAuth.getAllHousingSchemeList()
    setItems(response.data)
  }

  useEffect(()=> {
    getHousingSchemeData()
  }, [])


  useEffect(()=>{

   /* console.log("testt selected", selectedScheme)*/
  }, [selectedScheme])
  const formatResult = (item) => {
    return item;
  };
  return (
    <>
      <div style={styles.panelStyle} className="drop-shadow">
        <div>
          <img style={{width:"115px", height:"44.49px"}}
              onClick={() => window.location.href=("/")}
            height={isMobile() ? "50px" : "50px"}
            src={
              props.open
                ? require("../../../../assets/img/logo.svg").default
                : require("../../../../assets/img/logo.svg").default
            }
            alt="Property with surety"
          />
        </div>

        <div className="mt-2">
          <ReactSearchAutocomplete
            items={housingSchemes}
            placeholder={"Housing Scheme"}
            onSearch={handleOnSearch}
            onSelect={handleOnSelect}
            showIcon={false}
            formatResult={formatResult}
            styling={styles.hsPanelStyle}
          />
        </div>
        {
          selectedScheme.id && (
                <div className="mt-2">
                  <ReactSearchAutocomplete
                      items={plots}
                      placeholder={"Plot Street"}
                      onSearch={handleFormPlotSearch}
                       onSelect={handleOnSelectPlotSearch}
                      showIcon={false}
                      formatResult={formatResult}
                      styling={styles.panelStyle}
                  />
                </div>
            )
        }
      </div>
    </>
  );
}

const styles = {
  hsPanelStyle: {
    width: isMobile() ? 240 : 270,
    alignItems: "center",
    padding: 10,
    borderRadius: 10,
    paddingTop: 15,
    marginLeft: 7,
    justfyContent: "space-around",
    position: "absolute",
    zIndex: 2000,
    cursor:'pointer',
  },
  panelStyle: {
    width: isMobile() ? 240 : 270,
    alignItems: "center",
    padding: 10,
    borderRadius: 10,
    paddingTop: 15,
    marginLeft: 7,
    justfyContent: "space-around",
    position: "absolute",
    zIndex: 1500,
    cursor:'pointer',
  },

  searchStyling: {
    height: "44px",
    border: "1px solid #dfe1e5",
    borderRadius: "12px",
    backgroundColor: "white",
    boxShadow: "rgba(32, 33, 36, 0.28) 0px 1px 6px 0px",
    hoverBackgroundColor: "#eee",
    color: "#212121",
    fontSize: "12px",
    fontFamily: "Arial",
    lineColor: "rgb(232, 234, 237)",
    placeholderColor: "grey",
    clearIconMargin: "3px 14px 0 0",
    searchIconMargin: "0 0 0 16px",
  },
};

export default ElasticSearch;
