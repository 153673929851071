import {
    collection,
    query,
    where,
    getDocs,
    getFirestore,
} from "firebase/firestore";
import {getAuth} from "firebase/auth";
const TimeDifferenceCalculate = ($time) =>{
    let datetimes = new Date($time)
    let datetimeNow = new Date()
/*
    let $date = `${datetimes.getFullYear()}-${datetimes.getMonth()}-${datetimes.getDate()} ${datetimes.getHours()}:${datetimes.getMinutes()}:00`
    let $dateNow = `${datetimeNow.getFullYear()}-${datetimeNow.getMonth()}-${datetimeNow.getDate()} ${datetimeNow.getHours()}:${datetimeNow.getMinutes()}:00`
*/

    let $diifTime   = datetimeNow-datetimes
    return $diifTime/(60*60*1000)
}
const findArrayIndex = (rows,key,value) =>{
    let i =-1
    rows.map((row,index)=>{
        if (row[key] ===value){
            i = index
        }
    })
    return i
}
const PropsTimeFormat = ($datetime) =>{
    let datetimes = new Date($datetime)
    return `${datetimes.getFullYear()}-${datetimes.getMonth()}-${datetimes.getDate()} ${datetimes.getHours()}:${datetimes.getMinutes()}:00`
}


const userLoginData = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    const firebasedata = async (params) => {
        let user= {}
        const q = query(
            collection(getFirestore(), "users"),
            where("userID", "==", params)
        );
        const querySnapshot = await getDocs(q);
       await  querySnapshot.forEach((doc) => {
           user = doc._document.data.value.mapValue.fields
        });

       return user
    };
    return await firebasedata(user.uid)
}

const isMobileValid = (number) => {
    return  /^03\d{3}\d{6}$/.test(number);
}




export {
    TimeDifferenceCalculate,
    findArrayIndex,
    PropsTimeFormat,
    userLoginData,
    isMobileValid
}
