import React from 'react'

function CheckBoxDescSection(props) {

  const {checkedBoxValue , onChangeCheckedBox, label ,ids, infoArr}  = props;
  return (
    <div className={(checkedBoxValue)?'mws-checkbox checkbox-active':'mws-checkbox'} style={{ wordWrap:'break-word' ,wordBreak :'break-all'
      }}>
    <input
      class="form-check-input me-2  input-checkbox-mws"
      type="checkbox"
      value="planningSection"
      id={ids}
      onChange={onChangeCheckedBox}
    />

    <label class="form-check-label fw-bold label-mws" for={ids}>
     {label}
    </label>
    <ul className="lh-1 ms-4 mt-2 pt-1 text-small-mws">

    { infoArr?
      infoArr.map(info=><li className='fw-light'>{info}</li>) :<p>Add infoArr[] prop to show infos</p>
    }
    </ul>
  </div>
  )
}

export default CheckBoxDescSection
