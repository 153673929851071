import { geoserverbase, geoserverStore } from "../../../config";
import Axios from "axios";
import ApiAuth from "../../../API/ApiAuth";
import {toast} from "react-toastify";
import Auth from "../../../Redux/Actions/Auth";

const GetFeatureInfo = (centroid, map, setplotData,dispatch) => {
  let size = map.getSize(),
    crs = map.options.crs,
    bounds = map.getBounds(),
    xy = map.latLngToContainerPoint(centroid, map.getZoom()),
    sw = crs.project(bounds.getSouthWest()),
    ne = crs.project(bounds.getNorthEast());

  let params = {
    service: "WMS",
    version: "1.1.1",
    request: "GetFeatureInfo",
    INFO_FORMAT: "application/json",
   /* format: 'image/png',*/
    BBOX: sw.x + "," + sw.y + "," + ne.x + "," + ne.y,
    WIDTH: size.x,
    HEIGHT: size.y,
    layers: geoserverStore + "plot",
    query_layers: geoserverStore + "plot",
    srs: crs.code,
    x: Math.round(xy.x),
    y: Math.round(xy.y),
    feature_count: 1,
  };
  dispatch(Auth.loader(true))
  ApiAuth.plotDetails(params).then((response)=>{
    const {success,message,data} = response
    if (success === true ) {
      setplotData(data)
      toast.success(message)
    } else {
      setplotData('')
      toast.error(message)
    }
    dispatch(Auth.loader(false))
  }).catch((error)=>{
    toast.error(error)
    setplotData('')
    dispatch(Auth.loader(false))
  })
 /* return

  Axios.get(geoserverbase, {
    params: params,
  })
    .then(function (response) {
      // console.log(response);
      if (response && response.data.features) {
        setplotData(response.data.features[0]);
      }
    })
    .catch(function (error) {
       console.log(error);
    });*/
}

export default GetFeatureInfo;
