import {TAG_MANAGER_ID} from "../Redux/variables";

export const GoogleView = (title,url) => {
    // window.gtag('config', TAG_MANAGER_ID, {
    //     page_title:title,
    //     page_path: url,
    // })
}
export const GoogleEvent = ({ action, params }) => {
    console.log(action,params,'Hit')
    let l = window.dataLayer.push({'event':action,'conversionValue':params});
    let ev = window.gtag('event', action, params)
    console.log(ev,l,'Event Google')
}
