import L from "leaflet";
import { API_URL } from "./Redux/variables";
/*const geoserverbase = "https://geoserver.propsure.co/geoserver/propsure_prod/wms";*/
/*const geoserverbase = "https://geoserver.graana.rocks/geoserver/CDA/wms";*/
/*const geoserverbase = "http://3.233.139.215:8080/geoserver/CDA/wms";*/
let geoserverbase = '';

let CDA_BASE_URL = "";
const domainUrl  = window.location.host;
// @ts-ignore hello world
switch (domainUrl){
  case 'cdaopvs.graana.rocks':
    geoserverbase ="https://cdaadmin.graana.rocks/geoserver/CDA/wms";
    CDA_BASE_URL = "https://cda.graana.rocks/";
    break;
  case 'uat-cdaopvs.graana.rocks':
    geoserverbase ='https://uat-cdaadmin.graana.rocks/geoserver/CDA/wms';
    CDA_BASE_URL = "https://uat.cda.gov.pk/";
    break;
  case 'opvs.cda.gov.pk':
    geoserverbase ='https://geoserver.cda.gov.pk/geoserver/CDA/wms';
    CDA_BASE_URL = "https://cda.gov.pk/";
    break;
  default:
    geoserverbase ='https://geoserver.cda.gov.pk/geoserver/CDA/wms';
     CDA_BASE_URL = "https://cda.gov.pk/";
    break;
}


const geoserverStore = "CDA:";

const nodeProd = "https://propapp.propsure.co/api/";
const nodeDev = "http://localhost:3031/api/";

const laravelBaseUrl = "http://localhost:8000/api/";

const mapbounds = L.latLngBounds(
  L.latLng(34.092227964504396, 73.85732162522909),
  /*L.latLng(34.70549341022547, 86.22070312500001),*/
  L.latLng(33.49534078231472, 72.07006542414884)
  /*L.latLng(25.859223554761407, 52.47070312500001)*/
);

export {
  geoserverbase,
  geoserverStore,
  nodeProd,
  nodeDev,
  mapbounds,
  laravelBaseUrl,
  CDA_BASE_URL,
};
