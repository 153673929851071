import { Helmet } from "react-helmet";
import { getPageTitle, GOOGLE_CAPTCHA_SITE_KEY } from "../../Redux/variables";
import Header from "../../components/header/Header";
import { Redirect } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import ScrollButton from "../../components/scrollButton/ScrollButton";
import React, { useContext, useRef, useState } from "react";
import { isMobileValid } from "../../Helper";
import { toast } from "react-toastify";
import Auth from "../../Redux/Actions/Auth";
import ApiAuth from "../../API/ApiAuth";
import axios from "axios";
import { laravelBaseUrl } from "../../config";
import { useDispatch } from "react-redux";
import { AuthContext } from "../../context/AuthContext";
import loginButton from "../../components/loginButton";
import LoginButton from "../../components/loginButton";
import ReCAPTCHA from "react-google-recaptcha";
import LoginVideoModal from "../Home/LoginVideoModal";
import CdaFooter from "../../components/cdaFooter";
import PageHeader from "../../components/PageHeader";
import Headerbar from "../../components/NavHeader";
import CdaHeader from "../../components/cdaHeader";

const Register = (props) => {
  const dispatch = useDispatch();

  const [tutorialOpen, setTutorialOpen] = useState(false);
  const closeTutorialHandle = (value) => {
    setTutorialOpen(value);
    console.log(value, "open modal");
  };

  const RecaptchaRef = useRef()

  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const [signupEmail, setSignupEmail] = useState("");
  const [signupPassword, setSignupPassword] = useState("");
  const [signupFirstName, setSignupFirstName] = useState("");
  const [signupLastName, setSignupLastName] = useState("");
  const [signupContact, setSignupContact] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [userInfoMissing, setUserInfoMissing] = useState(false);
  const [mobileError, setMobileError] = useState("");
  const [registerChecked, setRegisterChecked] = useState(false);
  const setRegisterCheckedHandle = (e) => {
    setRegisterChecked(!registerChecked);
  };
  const registerRef = useRef(null);
  const handleChangeContact = (e) => {
    setMobileError("");
    setSignupContact(e.target.value);
    if (!isMobileValid(e.target.value)) {
      setMobileError("Mobile Number is not valid.");
    }
  };
  const [googleCaptcha, setGoogleCaptcha] = useState("");
  const onGoogleChange = (value) => {
    setGoogleCaptcha(value);
  };
  const handleSignup = async (e) => {
    e.preventDefault();
    if (!googleCaptcha) {
      toast.warning("Google Captcha is required.");
      return;
    }
    registerRef.current.innerHTML = "Please wait ...";
    registerRef.current.disabled = true;
    setMobileError("");
    if (!isMobileValid(signupContact)) {
      toast.error("Mobile Number is not valid.");
      registerRef.current.innerHTML = "Register";
      registerRef.current.disabled = false;
      return;
    }
    if (!registerChecked) {
      registerRef.current.innerHTML = "Register";
      registerRef.current.disabled = false;
      return;
    }
    dispatch(Auth.loader(true));
    await ApiAuth.register({
      f_name: signupFirstName,
      l_name: signupLastName,
      email: signupEmail,
      contact_no: signupContact,
      password: signupPassword,
      googleCaptcha: googleCaptcha,
    })
      .then((response) => {
        if (response.success === true) {
          toast.success(response.message);
          setSignupEmail("");
          setSignupFirstName("");
          setSignupLastName("");
          setSignupContact("");
          //setSignUpVisible(false);
          registerRef.current.innerHTML = "Register";
          registerRef.current.disabled = false;
        } else {
          const { message } = response;
          const keys = Object.keys(message);
          let messages = "";
          console.log(keys);
          keys.map((row, index) => {
            messages +=
              message[row][0] !== null &&
              message[row][0] !== "" &&
              message[row][0] !== undefined
                ? `${row} : ${message[row][0]} .`
                : "";
          });
          toast.error(messages);
          registerRef.current.innerHTML = "Register";
          registerRef.current.disabled = false;
        }
        registerRef.current.innerHTML = "Register";
        registerRef.current.disabled = false;
      })
      .catch((error) => {
        console.log(error, "Error");
        toast.error(error);
        registerRef.current.innerHTML = "Register";
        registerRef.current.disabled = false;
        dispatch(Auth.loader(false));
      });
  };
  return (
    <>
      <Helmet>
        <title>Register {getPageTitle()}</title>
        <meta name="description" content="" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Register | ${getPageTitle()}`} />
        <meta property="og:description" content="" />
        <meta property="og:url" content="/register" />
        <meta property="og:site_name" content="CDA Islamabad" />
        <meta property="article:publisher" content="" />
        <meta property="og:image" content="" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      {/* <Header state={props} /> */}
      <Headerbar />
      <CdaHeader />
      <PageHeader title="IPVS Registration Form"></PageHeader>
      {/* <ChatBox />
            <RealEstate />
            <Services />
            <PropMap />
            <WhyPropMap />
            <OurValues />*/}
      {currentUser && <Redirect to={"/map"} />}
      {/* <section className="second-section bg-red-line1" id="Services-section"> */}
      <div className="container services mb-5">
        <div className="row mt-0">
          <div style={{    margin: '70px auto 0px auto'}}  className="col-lg-5 col-md-5 offset-4 login-form">
            {/* <h3 className={"text-center"}>Register</h3> */}
            <form onSubmit={handleSignup}>
              <div className="mb-3">
                <input
                  value={signupFirstName}
                  pattern="[A-Za-zs ]{1,}"
                  type="text"
                  className="form-control"
                  placeholder="First name*"
                  id="fname"
                  onChange={(e) => {
                    setSignupFirstName(e.target.value);
                  }}
                  required
                  style={{ paddingBottom: "8px" }}
                />
              </div>
              <div className="mb-3">
                <input
                  value={signupLastName}
                  pattern="[A-Za-zs ]{1,}"
                  type="text"
                  className="form-control"
                  placeholder="Last name*"
                  id="lname"
                  onChange={(e) => {
                    setSignupLastName(e.target.value);
                  }}
                  required
                  style={{ paddingTop: "8px", paddingBottom: "8px" }}
                />
              </div>
              <div className="mb-3">
                <input
                  value={signupEmail}
                  type="email"
                  className="form-control"
                  placeholder="Email adress*"
                  id="email"
                  onChange={(e) => {
                    setSignupEmail(e.target.value);
                  }}
                  required
                  style={{ paddingTop: "8px", paddingBottom: "8px" }}
                />
              </div>
              <div className="mb-3">
                <input
                  value={signupPassword}
                  type="password"
                  className="form-control input-text block large "
                  placeholder="Password*"
                  id="password"
                  onChange={(e) => {
                    setSignupPassword(e.target.value);
                  }}
                  required
                  style={{ paddingTop: "8px", paddingBottom: "8px" }}
                />
              </div>
              <div className="mb-3">
                <input
                  value={signupContact}
                  type="text"
                  className="form-control"
                  placeholder="Contact*"
                  id="phnumber"
                  onChange={handleChangeContact}
                  style={{ paddingTop: "8px", paddingBottom: "8px" }}
                />
                {mobileError && (
                  <div className="text-danger mt-1">{mobileError}</div>
                )}
              </div>

              <div hidden={true} className="form-check">
                <input className="form-check-input" type="checkbox" />
                <label
                  className="form-check-label"
                  htmlFor="remember-check"
                  style={{ padding: "0%" }}
                >
                  I’d like to receive coupons, promotions, surveys, and updates
                  via email about CDA Islamabad and its partnering agencies.
                </label>
              </div>
              {errorMessage && (
                <text style={{ color: "red", alignSelf: "center" }}>
                  {errorMessage}
                </text>
              )}
              <div className="mt-3 d-grid" style={{ fontSize: "14px" }}>
                <p>
                  {/* <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultChecked={registerChecked}
                      id="remember-check"
                      onChange={(e) => {
                        setRegisterCheckedHandle(e);
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="remember-check"
                      style={{ padding: "0%" }}
                    >
                      By signing up, I agree to CDA islamabad{" "}
      
                    </label>
                  </div> */}
                </p>
              </div>
              <div className=" d-grid">
                <div className="captcha-wrapper1">
                  <ReCAPTCHA
                    size={"normal"}
                    sitekey={GOOGLE_CAPTCHA_SITE_KEY}
                    onChange={onGoogleChange}
                    ref={RecaptchaRef}
                  />
                  <button
                  type="button"
                  onClick={()=>RecaptchaRef.current.reset()} 
                  style={{ color: "#739b21", border: 'none', backgroundColor: 'transparent', fontSize:'small', cursor: 'pointer'}}
                >
                  Reset
                </button>
                </div>
              </div>
              <div className="mt-3 d-grid">
                <button
                  ref={registerRef}
                  disabled={!registerChecked || mobileError.length > 0}
                  className="btn btn-danger waves-effect waves-light pakistan-color"
                  type="submit"
                >
                  Register
                </button>
              </div>
              <LoginButton {...props} />

              {/*<div style={{display:'none'}} hidden={true} className="mt-3 d-grid text-center">
                <button
                  type={"button"}
                  onClick={() => {
                    setTutorialOpen(true);
                  }}
                  className={"text-color-green"}
                  style={{
                    textDecoration: "none",
                    cursor: "pointer",
                    border: "none",
                    background: "none",
                  }}
                >
                  View Tutorial
                </button>
              </div>*/}
            </form>
            {tutorialOpen && (
              <LoginVideoModal
                link={"https://app.cda.gov.pk/register-demo.mp4"}
                modalOpen={tutorialOpen}
                closeModel={closeTutorialHandle}
              />
            )}
          </div>
        </div>
      </div>
      {/* </section> */}
      <CdaFooter />
      {/* <Footer /> */}
      <ScrollButton />
    </>
  );
};
export default Register;
