// import { Button } from "@mui/material";
import { CDA_BASE_URL } from "../../config";
import "./index.css";

function Headerbar() {
  return (
    <nav>
      <div className="navbar">
        <a href={CDA_BASE_URL} className="Desktoplogo">
          <img src="logo.svg" alt="" width="150px" height="64.49px" />
        </a>
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <div
            style={{
              height: "25px",
              color: "#E5E5E5",
              width: "0px",
              border: "0.5px solid",
            }}
            className="hideOnMobile"
          />
          <button
            style={{
              borderRadius: "8px",
              border: "1px solid #739B21",
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "22.05px",
              background: "transparent",
              padding: "8px 16px 10px 16px",
            }}
            className="hideOnMobile"
          >
            <a
              href={`${CDA_BASE_URL}contact-us`}
              style={{ textDecoration: "none", color: "#739B21" }}
            >
              CONTACT US
            </a>
          </button>
          <div
            style={{
              height: "25px",
              color: "#E5E5E5",
              width: "0px",
              border: "0.5px solid",
            }}
            className="hideOnMobile"
          />
          <a
            className="hideOnMobile"
            href="https://twitter.com/CDAthecapital?ref_src=twsrc%5Etfw"
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noreferrer"
          >
            <img src="fb-cda.svg" width="30px" height="30px" alt="" />
          </a>
          <div
            style={{
              height: "25px",
              color: "#E5E5E5",
              width: "0px",
              border: "0.5px solid",
            }}
            className="hideOnMobile"
          />
          <a
            className="hideOnMobile"
            href="http://www.facebook.com/cda.isb.pk"
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noreferrer"
          >
            <img src="twitter-cda.svg" alt="" width="30px" height="30px" />
          </a>
        </div>
      </div>
    </nav>
  );
}

export default Headerbar;
