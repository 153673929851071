import React from "react";
import { isMobile } from "../../../utils";

function LayerSwitch(props) {
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (e.target.checked) {
      props.setplotfilter(value);
    }
  };

  return (
    <>
      <div style={styles.panelStyle} className="drop-shadow">
        <div style={{ marginTop: 1, marginLeft: 8, marginBottom: 8 }}>
          <input
            name="landuse_filter"
            value="all"
            id="all"
            style={{ top: 2 }}
            type="radio"
            checked={props.plotfilter === "all"}
            onChange={handleChange}
          />
          <label style={{ marginLeft: 5, fontSize: isMobile() ? 10 : 13 }}>
            All
          </label>
        </div>
        <div style={{ marginLeft: 8 }}>
          <input
            name="landuse_filter"
            value="Residential"
            id="residential"
            style={{ top: 2 }}
            type="radio"
            checked={props.plotfilter === "Residential"}
            onChange={handleChange}
          />
          <label style={{ marginLeft: 5, fontSize: isMobile() ? 10 : 13 }}>
            Residential
          </label>
        </div>
        <div style={{ marginTop: 8, marginLeft: 8 }}>
          <input
            name="landuse_filter"
            value="Commercial"
            id="commercial"
            style={{ top: 2 }}
            type="radio"
            checked={props.plotfilter === "Commercial"}
            onChange={handleChange}
          />
          <label style={{ marginLeft: 5, fontSize: isMobile() ? 10 : 13 }}>
            Commercial
          </label>
        </div>
      </div>
    </>
  );
}

const styles = {
  panelStyle: {
    width: isMobile() ? 150 : 200,
    height: isMobile() ? 100 : 108,
    backgroundColor: "#fff",
    alignItems: "center",
    paddingLeft: 8,
    borderRadius: 10,
    paddingTop: 5,
    marginRight: 8,
    justfyContent: "space-around",
    position: "absolute",
    right: "0px",
    bottom: isMobile() ? "330px" : "370px",
    zIndex: 900,
  },
};

export default LayerSwitch;
