import React, { useContext, useEffect, useRef, useState } from "react";
import ChatBox from "../../components/chatBox/ChatBox";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import OurValues from "../../components/ourValues/OurValues";
import PropMap from "../../components/propMapSection/PropMap";
import RealEstate from "../../components/realEstateSection/RealEstate";
import ScrollButton from "../../components/scrollButton/ScrollButton";
import Services from "../../components/servicesSection/Services";
import WhyPropMap from "../../components/whyPropMapSection/WhyPropMap";
import { GoogleEvent, GoogleView } from "../../Helper/GoogleView";
import { Helmet } from "react-helmet";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import {
  getPageTitle,
  GOOGLE_AUTH_CLIENT_ID,
  GOOGLE_CAPTCHA_SITE_KEY,
  removeSpacesFromString,
} from "../../Redux/variables";
import { isMobileValid } from "../../Helper";
import { toast } from "react-toastify";
import Auth from "../../Redux/Actions/Auth";
import ApiAuth from "../../API/ApiAuth";
import { useDispatch } from "react-redux";
import { AuthContext } from "../../context/AuthContext";
import { Redirect } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import swal from "sweetalert";
import LoginVideoModal from "./LoginVideoModal";
import { fontStyle, fontWeight } from "@mui/system";
import CdaHeader from "../../components/cdaHeader";
import CdaFooter from "../../components/cdaFooter";
import PageHeader from "../../components/PageHeader";
import Headerbar from "../../components/NavHeader";
// import CdaFooter from "../../components/cdaFooter";
const Home = (props) => {
  useEffect(() => {
    GoogleView("Home", "/");
  }, []);
  const { currentUser } = useContext(AuthContext);

  const RecaptchaRef = useRef()

  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(null);
  const loginRef = useRef(null);
  const [signinEmail, setSigninEmail] = useState("");
  const [signinPassword, setSigninPassword] = useState("");
  const [googleCaptcha, setGoogleCaptcha] = useState("");
  const onGoogleChange = (value) => {
    setGoogleCaptcha(value);
  };

  const handleSignin = async (e) => {
    e.preventDefault();
    if (!googleCaptcha) {
      toast.warning("Google Captcha is required.");
      return;
    }
    loginRef.current.innerHTML = "Please wait ...";
    loginRef.current.disabled = true;
    dispatch(Auth.loader(true));
    GoogleEvent({ action: "AttemptLogin", params: 1 });
    await ApiAuth.login({
      email: signinEmail,
      password: signinPassword,
      googleCaptcha: googleCaptcha,
    })
      .then((response) => {
        const { success, message, data } = response;
        if (success === true) {
          const { token, user } = data;
          dispatch(Auth.loader(false));
          dispatch(Auth.login({ token, user }));
          GoogleEvent({ action: "LoginSuccess", params: 1 });
          GoogleEvent({
            action: `USER_${removeSpacesFromString(user.name).toUpperCase()}`,
            params: user.email,
          });
          toast.success("Login Successfully");
          // setSignInVisible(false);
        } else {
          toast.error(message);
          GoogleEvent({ action: "LoginError", params: message });
        }
        loginRef.current.innerHTML = "Login";
        loginRef.current.disabled = false;
      })
      .catch((error) => {
        toast.error(error);
        loginRef.current.innerHTML = "Login";
        loginRef.current.disabled = false;
        dispatch(Auth.loader(false));
        GoogleEvent({ action: "LoginApiError", params: error });
      });
  };
  const [tutorialOpen, setTutorialOpen] = useState(false);
  // const [value, setValue] = React.useState(2);
  const [value, setValue] = useState(0); // Set the default value to 0 (index of "About IPVS" tab)

  const renderTabContent = () => {
    if (value === 0) {
      // Content for "About" tab
      return (
        <div style={{ marginTop: "32px" }}>
          <h3
            style={{
              textAlign: "left",
              fontFamily: "Poppins",
              color: "#37474F",
              fontWeight: "600",
              fontSize: 21,
              textAlign:"center",
              // padding: "10px",
              marginBottom: 30,
            }}
          >
            Initial Property Verification Service (IPVS)
          </h3>
          <p
            style={{
              fontFamily: "Poppins",
              color: "#37474F",
              // fontWeight: "400",
              fontSize: 14,
            }}
          >
            IPVS is a groundbreaking solution designed to provide you with
            unparalleled access to comprehensive and reliable property
            information. It serves as your gateway to streamlined property
            verification.
            <br></br>
            <br></br>
            We offer an extensive and regularly updated property database with
            diverse options. Our team ensures thorough verification, reviewing
            legal documents and property history for accuracy. Our user-friendly
            platform, IPVS, makes it easy to explore properties, access verified
            data, and make well-informed decisions effortlessly.
            <br></br>
            <br></br>
            The primary notion behind the launch of this service is to
            facilitate overseas Pakistanis, who are considered, “The supreme
            fortune of Pakistan”. Property verification while purchasing a
            property in any housing scheme, vertical or commercial project, has
            been made possible through ingenious efforts ofCDA. This service
            renders Property Verification Report (PVR) which is publicly
            available and bring forth the following information:
            <br></br>
            <br></br>
            <ul>
              <li>Address (Plot No, Street No, Gali No, Road Name)</li>
              <li>Housing Scheme Layout Plan (LOP) Approval Status</li>
              <li>Plot LOP Approval Status</li>
              <li>Type of Land Use permitted in Approved Layout Plan</li>
              <li>Total Area (Sq. Yds) information</li>
              <li>
                Competent Authority (for dealing approvals in its jurisdiction
                area)
              </li>
            </ul>
          </p>
        </div>
      );
    } else if (value === 1) {
      // Content for "How to use" tab
      return (
        <div style={{ marginTop: "32px" }}>
          <h3
            style={{
              fontFamily: "Poppins",
              fontSize: " 21.88px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "32.028px",
              textAlign:"center"
            }}
          >
            Your 6-Step Guide to CDA Inital Property Verification Service
          </h3>
          <p style={{ display: "flex", flexDirection: "column" }}>
            <br />
            <h6 className="headings">
              1. Register for IPVS (Initial Property Verification Service)
            </h6>
            <ul className="bullets">
              <li>Provide the required details to create your IPVS account.</li>
              <li>
                An email for verification will be sent to your provided email
                address.
              </li>
              <li>
                Click on the address provided in the email to successfully
                proceed with account creation.
              </li>
            </ul>
            <h6 className="headings">2. Login with Credentials </h6>
            <ul className="bullets">
              <li>
                Access your IPVS account securely by logging in with the
                credentials you used during registration.
              </li>
            </ul>

            <h6 className="headings">3. Input Property Details </h6>
            <ul className="bullets">
              <li>
                Select the desired address or property you wish to verify using
                the search field, or zoom into the map to selection of
                property/address.
              </li>
            </ul>
            <h6 className="headings">4. Select Your Service </h6>
            <ul className="bullets">
              <li>
                Choose the specific service you need from the left side menu.
              </li>
            </ul>
            <h6 className="headings">
              5. Request Initial Property Verification Report (OPVR)
            </h6>
            <ul className="bullets">
              <li>
                Click on the button “Get your Initial Property Verification
                Report (OPVR)” to proceed with the request.
              </li>
              <li>
                A success message will be shown confirming the submission of
                your request.
              </li>
            </ul>
            <h6 className="headings">6. Instant Report Delivery</h6>
            <ul className="bullets">
              <li>
                Your comprehensive Initial Property Verification Report will be
                delivered electronically to your account.{" "}
              </li>
            </ul>
          </p>
        </div>
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>Home {getPageTitle()}</title>
        <meta name="description" content="" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Home | ${getPageTitle()}`} />
        <meta property="og:description" content="" />
        <meta property="og:url" content="/" />
        <meta property="og:site_name" content="CDA Islamabad" />
        <meta property="article:publisher" content="" />
        <meta property="og:image" content="" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      {/* <Header state={props} /> */}
      {/* <ChatBox />
            <RealEstate />
            <Services />
            <PropMap />
            <WhyPropMap />
            <OurValues />*/}
      <Headerbar />
      <CdaHeader />
      <PageHeader title="A Step Towards Digital Pakistan" />
      {currentUser && <Redirect to={"/map"} />}

      <div className="container mainContainer">
        <div className="row innerContainer">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <Tabs
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
            >
              <Tab sx={{ textTransform: "none" }} label="About" />
              <Tab sx={{ textTransform: "none" }} label="How to use" />
            </Tabs>
            {renderTabContent()}
          </div>
          <div
            className="col-lg-6 col-md-12 col-sm-12 login-form login-box"
            style={{ backgroundColor: "white", marginTop: "70px" }}
          >
            <h3 className="login-title">Login</h3>
            <form
              onSubmit={handleSignin}
              style={{ width: "100%", justifyContent: "center" }}
            >
              <div className="form-group">
                <input
                  value={signinEmail}
                  onChange={(e) => {
                    setSigninEmail(e.target.value);
                  }}
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  id="email"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  value={signinPassword}
                  onChange={(e) => {
                    setSigninPassword(e.target.value);
                  }}
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  id="phnumber"
                  required
                />
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="remember-check"
                  // required
                />
                <label
                  className="form-check-label"
                  htmlFor="remember-check"
                  style={{
                    padding: "0%",
                    textAlign: "center",
                    color: "#737678",
                    /* H6 (Body Small) Regular */
                    fontFamily: "Poppins",
                    fontSize: "12.07px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    // lineHeight: "20.519px",
                  }}
                >
                  Remember me
                </label>
                <a
                  href="javascript:void(0)"
                  onClick={() => {
                    props.history.push("/forget-password");
                  }}
                  className={"text-color-green"}
                  style={{
                    textDecoration: "none",
                    float: "right",
                  }}
                >
                  Forget Password?
                </a>
              </div>
              {errorMessage && (
                <text style={{ color: "red", alignSelf: "center" }}>
                  {errorMessage}
                </text>
              )}
              <div className="mt-3 d-grid" style={{ justifyContent: "center" }}>
              <div className="captcha-wrapper1">
                  <ReCAPTCHA
                    sitekey={GOOGLE_CAPTCHA_SITE_KEY}
                    onChange={onGoogleChange}
                    ref={RecaptchaRef}
                  />
                  <button
                    type="button"
                    onClick={()=>RecaptchaRef.current.reset()} 
                    style={{ color: "#739b21", border: 'none', backgroundColor: 'transparent', fontSize:'small', cursor: 'pointer'}}
                  >
                    Reset
                  </button>
                </div>
              </div>
              <div className="d-grid">
                <button
                  className="btn btn-danger waves-effect waves-light pakistan-color"
                  type="submit"
                  style={{ marginTop: 20, borderRadius: 10 }}
                  onClick={handleSignin}
                  ref={loginRef}
                >
                  Login
                </button>
              </div>

              <div className="mt-3 d-grid text-center">
                <a
                  style={{
                    marginBottom: 0,
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: "12.1px",
                    fontWeight: "400",
                    color: "#739B21",
                    textDecoration: "none",
                  }}
                  href="/register"
                >
                 Don’t have an account? Register Now
                </a>
              </div>
              {/* Add more form elements as needed */}
              {/* <div className="form-group">
          <button className="btn-login" type="submit" onClick={handleSignin}>
            Login
          </button>
        </div> */}
            </form>
          </div>
          <div
            className="col-lg-12 col-md-12 login-form small"
            style={{ paddingRight: "60px", fontSize: "12px" }}
          >
            <h3 className={"text-center"}>Disclaimer</h3>
            <p>
              {" "}
              The contents of this Property Verification Report (“PVR”) are
              based on publicly available information owned and controlled by
              the Capital Development Authority through Housing, Estate and Land
              Directorates which have been reproduced for the purpose of this
              PVR. This PVR is not intended to substitute, disprove or confirm
              any information owned and/or controlled by CDA and does not
              address any specific requirements or provide advice on which
              reliance should be placed. Subject to applicable laws, Property
              Verification Service, CDA shall not be liable for any loss or
              damage, whether direct or indirect, resulting from and/or arising
              out of reliance placed on anything contained in this PVR. However,
              in larger public interest , Citizens are requested to check about
              the following details before ascertaining their decision regarding
              a particular property : <br />
              <b>
                Common errors in interest of citizens and investors (general
                guidance):
              </b>
            </p>
            <ul style={{ listStyleType: "number" }}>
              <li>
                Plots purchased in excess of total approved area - illegally
                extended areas shall be excluded
              </li>
              <li>Planning specs in approved LOP if changed</li>
              <li>
                Non-transferable public amenities (mosques, parks) utilised in
                violation to residential area or residential areas converted
                into commercial ones
              </li>
              <li>
                Mortgage plots - with CDA gradually released with development of
                roads , streets , gas and electricity etc which shall not be
                purchased unless cleared with CDA shall also be looked
              </li>
              <li>
                BCS requirements- Building completion certification , sanctioned
                area and number of approved units in building and Lay out plan
              </li>
            </ul>
            <p className="mb-0">
              For any further information or any queries including verification
              of the contents of this PVR and further details ; please contact
              One Window CDA at G7/4 Islamabad OR Office of Director Housing CDA
              on <a href="tel:051-9252630">051-9252630</a>
            </p>
            <p>
              {" "}
              <b> Note </b>: Given the changing status of the housing societies
              , the list mentioned is not exhaustive and may be updated on
              regular basis by Housing directorate CDA. Errors and Omissions are
              accepted.
            </p>
          </div>
        </div>
      </div>
      {/* </section> */}
      <CdaFooter />
      {/* <Footer /> */}
      {/* <ScrollButton /> */}
    </>
  );
};

export default Home;
