import { isMobile } from "../../../../utils";

const LayerLegends = (props) =>{
    return(
        <>
        {/* Land use legend plot layer */}
        {props.mapzoom >=17 && 
        (<div style={styles.dpanelStyle} >
            <img
            style={{ width: isMobile() ? 150 : 200 }}
            src={require("../../../../assets/img/plot_Legend.svg").default}
            />
        </div>)}

        {/* Housing Scheme legend */}
        {props.mapzoom >=12 && props.mapzoom <= 16 && 
        (<div style={styles.dpanelStyle}>
            <img
            style={{  width: isMobile() ? 150 : 200 }}
            src={require("../../../../assets/img/hs_Legend.svg").default}
            />
        </div>)}

        </>
    )
}

const styles = {
  
    dpanelStyle: {
      alignItems: "center",
      padding: 0,
      marginRight: 8,
      justfyContent: "space-around",
      position: "absolute",
      right: "0px",
      bottom: "225px",
      zIndex: 999,
    },

  };
  
  export default LayerLegends
