import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";

import { Drawer } from "@mui/material";

import axios from "axios";
import "./infoPanel.css";
import swal from "sweetalert";
import ReCAPTCHA from "react-google-recaptcha";

import {
    collection,
    query,
    where,
    getDocs,
    getFirestore,
} from "firebase/firestore";
import { isMobile } from "../../../../utils";
import {useContext, useEffect, useRef, useState} from "react";
import {AuthContext} from "../../../../context/AuthContext";
import ApiAuth from "../../../../API/ApiAuth";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import {GOOGLE_CAPTCHA_SITE_KEY, locateYourProperty, uniqByKeepFirst} from "../../../../Redux/variables";
import CheckBoxDescSection from "../CheckBoxDescSection";
import CloseIcon from "../../../Home/closeIcon";
import html2canvas from "html2canvas";


const userdata = [];

const firebasedata = async (params) => {
    const q = query(
        collection(getFirestore(), "users"),
        where("userID", "==", params)
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        userdata.push(doc._document.data.value.mapValue.fields);
    });
};

const InfoPanel = (props) => {


    const [planningWing,setPlanningWing] = useState(false);
    const [buildingControl,setBuildingControl] = useState(false);
    const [buildingPlanApprovalStatus,setBuildingPlanApprovalStatus] = useState(false);
    const [completionCertificate ,setCompletionCertificate] = useState(false);
    const [buttonReportDisabled ,setButtonReportDisabled] = useState(true);

    const RecaptchaRef = useRef()
    
    useEffect(() => {
        let a  = [];
        if (planningWing === true){
            setButtonReportDisabled(false)
        }
        if (buildingControl === true){
            setButtonReportDisabled(false)
        }
        if(buildingPlanApprovalStatus === true) {
            setButtonReportDisabled(false)
        }
        if(completionCertificate === true) {
            setButtonReportDisabled(false)
        }
        if (planningWing === false &&
            buildingControl === false &&
            buildingPlanApprovalStatus === false &&
            completionCertificate === false){
            setButtonReportDisabled(true)
        }
       /* if (planningWing === true){
            a.push(0)
        }
        else {
            a.push(1);
        }
        if (buildingPlanApprovalStatus === true){
            setBuildingControl(true);
            a.push(0)
        } else {
            a.push(1)
        }
        if (completionCertificate === true) {
            setBuildingControl(true);
            a.push(0)
        } else {
            a.push(1)
        }

        if (buildingPlanApprovalStatus === false && completionCertificate === false){
            setBuildingControl(false);
        }*/
        /*let unique_array    =   uniqByKeepFirst(a);
        if (unique_array.length ===1 && unique_array[0] ===0){
            setButtonReportDisabled(false)
        } else {
            setButtonReportDisabled(true)
        }
        console.log(unique_array,'unique',a)*/
    },[planningWing,buildingPlanApprovalStatus,completionCertificate])
    const userData = useSelector((state => state.user))

    const [buttonLoader,setButtonLoader] = useState(false)
    const { currentUser } = useContext(AuthContext);
    const [googleCaptcha,setGoogleCaptcha] = useState('')

    const [plotLoaded,setPlotLoaded]  = useState(false)
    useEffect(() => {
        setPlotLoaded(userData.loader)
    },[userData.loader])
    useEffect(() => {
        if (!currentUser && Object.keys(currentUser) === 0 ){
            props.history.push('/')
        }
    },[])
    /* firebasedata(props.user.uid);*/

    const onGoogleChange = (value) =>{
        setGoogleCaptcha(value);
        console.log(value,'VALUE Google')
    }

    const sendRequest = async (event) => {
        if (!googleCaptcha) {
            swal({ icon: "warning", text: 'Google Captcha is required' });
            return;
        }
        if (!currentUser || Object.keys(currentUser).length === 0) {
            props.history.push('/');
            return;
        }
        let contact_number = '';
        const fullName = currentUser.name.split(' ');
        let f_name = '';
        let l_name = '';
        if (fullName.length === 2) {
            f_name = fullName[0];
            l_name = fullName[1];
        } else {
            f_name = fullName[0];
            l_name = fullName.slice(1).join(' ');
        }
    
        // console.log(f_name, l_name);
        let user_id = currentUser.id,
            email = currentUser.email,
            housing_scheme = props.plotData.properties.housing_scheme_id,
            plot_id = Number(props.plotData.properties.id),
            contact_no = contact_number,
            city_id = props.plotData.properties.city_id;
    
        let data = {
            f_name,
            l_name,
            email,
            user_id,
            contact_no,
            reference_no: 'pvc',
            housing_scheme: housing_scheme,
            plot_id: plot_id,
            city_id: city_id,
            domain: 'propsure',
            googleCaptcha: googleCaptcha,
            planning_wings: planningWing ? 1 : 0,
            building_control: buildingControl ? 1 : 0,
            building_report: buildingPlanApprovalStatus ? 1 : 0,
            certification_report: completionCertificate ? 1 : 0,
            img_src: null
        };
    
        setButtonLoader(true);
        
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
        const rectWidth = 600;
        const rectHeight = 600;
        const x = (screenWidth - rectWidth) / 2;
        const y = (screenHeight - rectHeight) / 2;
        const canvas = await html2canvas(document.getElementById('root'), {
            x,
            y,
            width: rectWidth,
            height: rectHeight,
            scrollX: 0,
            scrollY: 0,
            windowWidth: screenWidth,
            windowHeight: screenHeight,
            useCORS: true,
          });
        //   const context = canvas.getContext('2d');

        //   // Define the coordinates of the polygon
        //   const canvasWidth = canvas.width;
        //   const canvasHeight = canvas.height;
      
        //   // Define the size of the polygon
        //   const polygonSize = 100; // Size of the polygon
        //   const halfPolygonSize = polygonSize / 2;
      
        //   // Calculate the coordinates of the polygon relative to the center of the canvas
        //   const centerX = canvasWidth / 2;
        //   const centerY = canvasHeight / 2;
        //   const polygonCoords = [
        //     [centerX - halfPolygonSize, centerY - halfPolygonSize], // Top-left corner
        //     [centerX + halfPolygonSize, centerY - halfPolygonSize], // Top-right corner
        //     [centerX + halfPolygonSize, centerY + halfPolygonSize], // Bottom-right corner
        //     [centerX - halfPolygonSize, centerY + halfPolygonSize]  // Bottom-left corner
        //   ];
      
        //   // Draw the polygon on the canvas
        //   context.beginPath();
        //   context.moveTo(polygonCoords[0][0], polygonCoords[0][1]);
        //   for (let i = 1; i < polygonCoords.length; i++) {
        //     context.lineTo(polygonCoords[i][0], polygonCoords[i][1]);
        //   }
        //   context.closePath();
        //   context.fillStyle = 'rgba(255, 0, 0, 0.5)'; // Fill color with opacity
        //   context.fill();
        
         const image = canvas.toDataURL('image/png');
        data.img_src = image;
    
        // Sending plot request
        ApiAuth.sendPlotRequest(data).then((response) => {
            const { success, message, data } = response;
            setButtonLoader(false);
            if (success === true) {
                swal({
                    icon: "success",
                    text: 'Thank you for submitting your request.\n' +
                        'You will receive your report shortly on your registered email address.'
                }).then((value) => {
                    window.location.reload();
                });
            } else {
                swal({ icon: "error", text: message });
            }
        }).catch((error) => {
            setButtonLoader(false);
            toast.error(error);
        });
    }
    

    // const sendRequest = (event) => {

    //     if (!googleCaptcha){
    //         swal({ icon: "warning", text: 'Google Captcha is required' });
    //         return;
    //     }
    //     if (!currentUser && Object.keys(currentUser) === 0 ){
    //         props.history.push('/')
    //     }
    //     let contact_number = '';
    //     const fullName = (currentUser.name).split(' ')
    //     console.log(fullName)
    //     let f_name =''
    //     let l_name = ''
    //     if (fullName.length ===2){
    //         f_name = fullName[0]
    //         l_name = fullName[1]
    //     }
    //     else if(fullName.length ===1){
    //         f_name = fullName[0]
    //         l_name = fullName[0]
    //     }

    //     else if(fullName.length ===3){
    //         f_name = fullName[0]
    //         l_name = `${fullName[1]} ${fullName[2]}`
    //     }
    //     else if(fullName.length ===4){
    //         f_name = fullName[0]
    //         l_name = `${fullName[1]} ${fullName[2]} ${fullName[3]}`
    //     }
    //     else if(fullName.length ===5){
    //         f_name = fullName[0]
    //         l_name = `${fullName[1]} ${fullName[2]} ${fullName[3]} ${fullName[4]}`
    //     }

    //     console.log(f_name,l_name)
    //     let user_id = currentUser.id,

    //         email = currentUser.email,
    //         housing_scheme = props.plotData.properties.housing_scheme_id,
    //        /* plot_id = Number(props.plotData.id.split(".")[1]),*/
    //         plot_id = Number(props.plotData.properties.id),
    //         contact_no = contact_number,
    //         city_id = props.plotData.properties.city_id;

    //     let data = {
    //         f_name,
    //         l_name,
    //         email,
    //         user_id,
    //         contact_no,
    //         reference_no:'pvc',
    //         housing_scheme:housing_scheme,
    //         plot_id:plot_id,
    //         city_id:city_id,
    //         domain:'propsure',
    //         googleCaptcha:googleCaptcha,
    //         planning_wings:planningWing?1:0,
    //         building_control:buildingControl?1:0,
    //         building_report:buildingPlanApprovalStatus?1:0,
    //         certification_report:completionCertificate?1:0
    //     }

    //     setButtonLoader(true)
    //     ApiAuth.sendPlotRequest(data).then((response) =>{
    //         const {success,message,data} = response
    //         setButtonLoader(false)
    //         if (success === true){
    //           /*  var payment_url = `https://payment.propsure.co/?customer_request=${data.erp_request_id}`;*/
    //             swal({ icon: "success", text: 'Thank you for submitting your request.\n' +
    //                     'Our dedicated team has already started working on your request.\n' +
    //                     'You will receive your report within the next 48 Hours.' }).then((value) => {
    //             /*    window.location.href = payment_url;*/
    //                 window.location.reload()
    //             })
    //         } else {
    //             swal({ icon: "error", text: message });
    //         }
    //     }).catch((error) => {
    //         setButtonLoader(false)
    //         toast.error(error)
    //     })

        /*    return
            const url = `https://propapp.propsure.co/api/requestVerification?f_name=${f_name}&l_name=${l_name}&contact_no=${contact_no}&email=${email}&reference_no=pvc&city_id=${city_id}&hosuing_scheme=${housing_scheme}&plot_id=${plot_id}&user_id=${user_id}`;

            // console.log(url);
            // return false;
            axios({
              method: "post",
              url: url,
              headers: {
                authToken: `${props.user.accessToken}`,
              },
            })
                .then(function (response) {
                  console.log(response.data);
                  if (
                      response &&
                      response.data.message == "Request Submitted Successfully"
                  ) {
                    var payment_url = `https://payment.propsure.co/?customer_request=${response.data.req_id}`;
                    swal({ icon: "success", text: "Request Submitted Successfully!" }).then((value) => {
                      window.location.href = payment_url;
                    })
                  } else {
                    //swal({ icon: "Error", text: "Request Not Sent!" });
                    swal({
                      icon: "success",
                      text: "Comming Soon!, You will get a surprise",
                    });
                    return false;
                  }
                })
                .catch(function (error) {
                  console.log(error);
                })
                .then(function () {
                  // always executed
                });*/
    // }

    const toggleDrawer = (anchor, open) => (event) => {
        console.log(anchor,open,'click func')
        //props.setState({ ...props.state, [anchor]: open });
    };
    const toggleDrawerCustom = (anchor, open) => {
        props.setState({[anchor]: open });
    };



    const list = (anchor) => (
        <>
            <Box
                className={'test-class'}
                sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 280 }}
                role="presentation"
                onClick={toggleDrawer(anchor, true)}
                onKeyDown={toggleDrawer(anchor, true)}
            >

                {!isMobile() && (
                    <img src={require("../../../../assets/img/search-bg.png").default} />
                )}
                {!isMobile() && (
                    <div className={'iconZIndex'} style={{position:'relative'}}>
                        <CloseIcon styles={{
                            'position':'absolute',
                            'right':'14px',
                            'top':'10px',
                            'cursor':'pointer',
                            zIndex:'99999 !important'
                        }} closePopup={props.state[anchor]} setClosePopup={(value) =>{
                            console.log(props.state,props,value,'click now')
                            toggleDrawerCustom(props.state[anchor],value)
                          //  props.setState({[anchor]:value})
                        }}/>
                    </div>
                )}
                {
                    (plotLoaded) ? (
                        <div className={'text-danger text-center mt-5 mb-2'}><b>Please wait...</b></div>
                    ) : (
                        (props.plotData) && (<>
                                <List className="border border-2 sm m-2 my-5 mt-2">
                                    <div className="fw-bold mx-2">Address:</div>
                                    <div className="m-2 text-danger">
                                        {/* plot */}
                                        {props.plotData &&
                                            "Plot/House # " + props.plotData.properties.plot_no}

                                        {/* street */}
                                        {props.plotData &&
                                            props.plotData.properties.street &&
                                            " Street " + props.plotData.properties.street}

                                        {/* phase sector */}
                                        {props.plotData &&
                                            props.plotData.properties.phase_sector_name &&
                                            " " + props.plotData.properties.phase_sector_name}
                                        {/* housing scheme */}
                                        {props.plotData &&
                                            props.plotData.properties.housing_scheme_name &&
                                            " " + props.plotData.properties.housing_scheme_name}

                                        {/* city name */}
                                        {props.plotData &&
                                            props.plotData.properties.city_name &&
                                            " " + props.plotData.properties.city_name}
                                    </div>
                                </List>
                            </>
                        )
                    )
                }
            </Box>
            {
                (!plotLoaded) && (
                    <Box  className="d-flex justify-content-center">
                        {props.plotData &&
                            (props.plotData.properties.land_use == "Commercial" ||
                                props.plotData.properties.land_use == "Residential") && (
                                <div
                                    className="button-portion"
                                    style={{ position: "relative", bottom: 26 }}
                                >
                                    <div className="property-checkboxes">
                                        <ul className={'list-group mb-2'}>
                                            <li className={'list-group-item'}>
                                                <CheckBoxDescSection
                                                    Ids={'planning-wing'}
                                                    label= {'Planning Wing, CDA'}
                                                    infoArr = {['Housing Scheme Layout Plan' , 'Plot Layout Plan' , 'Land Use']}
                                                    checkedBoxValue={planningWing}
                                                    onChangeCheckedBox={(e) =>{
                                                        console.log(e.target.checked,'Planning wings')
                                                        setPlanningWing(e.target.checked)
                                                    }
                                                    }
                                                />
                                            </li>
                                            <li hidden={true} className={'list-group-item'}>
                                                <div className="main-header-li">
                                                    <label>Building Control Section</label>
                                                </div>
                                                <ul className={'list-group'}>
                                                    <li className={'list-group-item'}>
                                                        <CheckBoxDescSection
                                                            ids={'building-plan-approval-status'}
                                                            label= {'Building plan approval status'}
                                                            infoArr = {[/*'Housing Scheme Layout Plan' , 'Plot Layout Plan','Land Use' , 'Building Plan Approval'*/]}
                                                            checkedBoxValue={buildingPlanApprovalStatus}
                                                            onChangeCheckedBox={(e) =>{
                                                                console.log(e.target.checked,'Building plan')
                                                                setBuildingControl(e.target.checked)
                                                                setBuildingPlanApprovalStatus(e.target.checked)
                                                            }
                                                            }
                                                        />
                                                    </li>
                                                    <li className={'list-group-item'}>
                                                        <CheckBoxDescSection
                                                            ids={'completion-certificate'}
                                                            label= {'Building completion certificate'}
                                                            infoArr = {[/*'Housing Scheme Layout Plan' , 'Plot Layout Plan','Land Use' , ' Completion Certificate'*/]}
                                                            checkedBoxValue={completionCertificate}
                                                            onChangeCheckedBox={(e) =>{
                                                                console.log(e.target.checked,'Building completion certificate')
                                                                setCompletionCertificate(e.target.checked)
                                                            }
                                                            }
                                                        />
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>

                                    </div>

                                    <div className="captcha-wrapper">
                                        <ReCAPTCHA
                                            size={'compact'}
                                            sitekey={GOOGLE_CAPTCHA_SITE_KEY}
                                            onChange={onGoogleChange}
                                            ref={RecaptchaRef}
                                        />
                                        
                                        <button
                                            type="button"
                                            onClick={()=>window.grecaptcha.reset()} 
                                            style={{ color: "#739b21", border: 'none', backgroundColor: 'transparent', fontSize:'small', cursor: 'pointer', marginBottom: '20px'}}
                                        >
                                            Reset
                                        </button>
                                    </div>
                                    <div className="text-center">
                                        <button hidden={false}
                                                disabled={buttonLoader || buttonReportDisabled}
                                                type={'button'}
                                                onClick={sendRequest}
                                                className="request-btn"
                                        >{buttonLoader?'Please wait ...':locateYourProperty()}</button>
                                    </div>
                                </div>
                            )}
                    </Box>
                )
            }
        </>
    );

    return (
        <>
            {/* <div style={{ position: "absolute" }}>
      <React.Fragment key={"left"}>
        <Drawer
          anchor={"left"}
          open={props.state[anchor]}
          onClose={toggleDrawer("left", true)}
          onOpen={toggleDrawer("left", true)}
          variant="persistent"
        >
          {list(props.state[anchor])}
        </Drawer>
      </React.Fragment>
    </div> */}

            <div style={{ position: "absolute" }}>
                {["left", "right", "top", "bottom"].map((anchor) => (
                    <React.Fragment key={anchor}>
                        <Drawer
                            anchor={anchor}
                            open={props.state[anchor]}
                            onClose={toggleDrawer(anchor, true)}
                            onOpen={toggleDrawer(anchor, true)}
                            variant="persistent"
                            className={'sidebar-address'}
                        >
                            {isMobile() && (
                                <div className={'iconZIndex'} style={{position:'relative'}}>
                                    <CloseIcon styles={{
                                        'position':'absolute',
                                        'right':'14px',
                                        'top':'10px',
                                        'cursor':'pointer',
                                        zIndex:'99999 !important'
                                    }} closePopup={props.state[anchor]} setClosePopup={(value) =>{
                                        props.setState({[anchor]:value})
                                    }}/>
                                </div>
                            )}
                            {list(anchor)}
                        </Drawer>
                    </React.Fragment>
                ))}
            </div>
        </>
    );
}
export default InfoPanel
