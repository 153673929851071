import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../../components/Sidebar";
import { isMobileValid, userLoginData } from "../../Helper";
import { db, firebase } from "../../firebase/firebase";
import { getAuth } from "firebase/auth";
import { Helmet } from "react-helmet";
import { getPageTitle } from "../../Redux/variables";
import CdaFooter from "../../components/cdaFooter";
import {
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
} from "firebase/firestore";
import CdaHeader from "../../components/cdaHeader";
import { useDispatch, useSelector } from "react-redux";
import ApiAuth from "../../API/ApiAuth";
import { toast } from "react-toastify";
import { errorMessages } from "../../Redux/variables";
import Auth from "../../Redux/Actions/Auth";
import { GoogleView } from "../../Helper/GoogleView";
import Headerbar from "../../components/NavHeader";
import PageHeader from "../../components/PageHeader";
const Profile = (props) => {
  const dispatch = useDispatch();
  const [userId, setUserId] = useState("");
  const [formData, setFormData] = useState({});

  const userData = useSelector((state) => state.user);

  useEffect(() => {
    let userLogin = Object.keys(userData.user).length > 0 ? userData.user : {};
    if (Object.keys(userLogin).length === 0) {
      props.history.push("/");
    }
  }, []);
  useEffect(() => {
    GoogleView("Profile", "/profile");
  }, []);

  useEffect(() => {
    let userLogin = Object.keys(userData.user).length > 0 ? userData.user : {};
    const name = userLogin.name;
    setFormData({
      name: userLogin.name,
      phone: userLogin.contact_no,
      email: userLogin.email,
    });
  }, [userData.user]);

  /*  useEffect(() => {
      userLoginData().then((user) => {
        console.log(user);
        setUserId(user.userID.stringValue);
        setFormData({
          firstName: user.firstName.stringValue,
          lastName: user.lastName.stringValue,
          phone: user.contact.stringValue,
          email: user.email.stringValue,
        });
      });
    }, []);*/
  const buttonRef = useRef(null);
  const [formSuccess, setFormSuccess] = useState("");
  const [formError, setFormError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const handleChangeContact = (e) => {
    setPhoneError("");
    if (!isMobileValid(e.target.value)) {
      setPhoneError("Mobile Number is not valid.");
    }
    setFormData({
      ...formData,
      phone: e.target.value,
    });
  };
  const updateProfile = async (e) => {
    e.preventDefault();

    let flag = 0;
    setFormSuccess("");
    setFormError("");
    setPhoneError("");
    setFirstNameError("");
    if (formData.firstName === "") {
      flag = 1;
      setFirstNameError("First Name is required");
    }
    if (!isMobileValid(formData.phone)) {
      setPhoneError("Mobile Number is not valid.");
      flag = 1;
    }
    if (flag === 1) {
      return;
    }
    buttonRef.current.innerHTML = "Please Wait ...";
    buttonRef.current.disabled = true;
    ApiAuth.updateProfile({
      name: formData.name,
      contact_no: formData.phone,
      password: formData.password,
    })
      .then((response) => {
        const { success, data, message } = response;
        if (success === true) {
          setFormSuccess(message);
          dispatch(Auth.updateUser(data.user));
        } else {
          setFormError(errorMessages(message));
        }
        buttonRef.current.innerHTML = "Update";
        buttonRef.current.disabled = false;
      })
      .catch((error) => {
        toast.error(error);
        buttonRef.current.innerHTML = "Update";
        buttonRef.current.disabled = false;
      });
    return;
    const docRef = doc(db, "users", userId);

    await updateDoc(docRef, {
      firstName: formData.firstName,
      lastName: formData.lastName,
      contact: formData.phone,
    }).then((res) => {
      buttonRef.current.innerHTML = "Update";
      setFormSuccess("Profile Update Successfully.");
    });
    /*const citiesRef = collection(db, "users");
        const q = query(citiesRef, where("userID", "==", userId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (doc) => {
            console.log(doc.id, " => ", doc.data());
        });*/
  };
  const handleChangeInput = (e, name) => {
    setFormData({
      ...formData,
      [name]: e.target.value,
    });
  };
  return (
    <>
      <Helmet>
        <title>Profile {getPageTitle()}</title>
        <meta name="description" content="" />
        {/* <meta name="robots" content="index, follow" />
<meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
<meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" /> */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Home | PropSure" />
        <meta property="og:description" content="" />
        <meta property="og:url" content="https://www.propsure.com.pk/" />
        <meta property="og:site_name" content="PropSure" />
        <meta property="article:publisher" content="" />
        <meta property="og:image" content="" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      {/* <Header state={props} /> */}
      <Headerbar />
      <CdaHeader />
      <PageHeader title="Profile"></PageHeader>
      <div className="bg-red-color">
        <section className="second-section bg-red-line1" id="Services-section">
          <div className="">
            <div className="container services">
              <div className="row">
                <div className="col-lg-3 col-md-3 leftSide">
                  <Sidebar {...props} />
                </div>
                <div className="col-lg-9 col-md-9 rightSide">
                  <div className="card">
                    <div className="card-header">Profile</div>
                    <div className="card-body">
                      {formSuccess && (
                        <div className="alert alert-success mt-2 mb-2">
                          {formSuccess}
                        </div>
                      )}
                      {formError && (
                        <div
                          className="alert alert-danger mt-2 mb-2"
                          dangerouslySetInnerHTML={{ __html: formError }}
                        />
                      )}

                      <form onSubmit={updateProfile}>
                        <div className="mb-3">
                          <label
                            htmlFor=""
                            style={{
                              fontSize: "16.88px",
                              fontWeight: "400",
                              fontFamily: "Poppins",
                            }}
                          >
                            Full Names
                          </label>
                          <input
                            onChange={(e) => {
                              handleChangeInput(e, "name");
                            }}
                            defaultValue={formData?.name}
                            type="text"
                            name={"name"}
                            className="form-control input-text block large "
                            placeholder="Full Name"
                            style={{
                              fontSize: "14.88px",
                              fontWeight: "400",
                              fontFamily: "Poppins",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor=""
                            style={{
                              fontSize: "16.88px",
                              fontWeight: "400",
                              fontFamily: "Poppins",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                            }}
                          >
                            Email
                          </label>
                          <input
                            type="text"
                            readOnly={true}
                            defaultValue={formData?.email}
                            name={"email"}
                            disabled={true}
                            readOnly={true}
                            className="form-control input-text block large "
                            placeholder="Last Name"
                            style={{
                              fontSize: "14.88px",
                              fontWeight: "400",
                              fontFamily: "Poppins",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                            }}

                            // style={{paddingTop: "8px", paddingBottom: "8px"}}
                          />
                        </div>

                        <div className="mb-3">
                          <label
                            htmlFor=""
                            style={{
                              fontSize: "14.88px",
                              fontWeight: "400",
                              fontFamily: "Poppins",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                            }}
                          >
                            Phone Number
                          </label>
                          <input
                            type="text"
                            defaultValue={formData.phone}
                            onChange={handleChangeContact}
                            name={"phoneNumber"}
                            autoComplete={"off"}
                            className="form-control input-text block large "
                            placeholder="Phone Number"
                            style={{
                              fontSize: "14.88px",
                              fontWeight: "400",
                              fontFamily: "Poppins",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                            }}
                            // style={{paddingTop: "8px", paddingBottom: "8px"}}
                          />
                          {phoneError && (
                            <div
                              className="text-danger mt-1"
                              style={{
                                fontSize: "14.88px",
                                fontWeight: "400",
                                fontFamily: "Poppins",
                              }}
                            >
                              {phoneError}
                            </div>
                          )}
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor=""
                            style={{
                              fontSize: "14.88px",
                              fontWeight: "400",
                              fontFamily: "Poppins",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                            }}
                          >
                            Password
                          </label>
                          <input
                            onChange={(e) => {
                              handleChangeInput(e, "password");
                            }}
                            defaultValue={formData?.password}
                            type="password"
                            name={"password"}
                            className="form-control input-text block large "
                            placeholder="Password"
                            style={{
                              fontSize: "14.88px",
                              fontWeight: "400",
                              fontFamily: "Poppins",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                            }}
                            // style={{paddingTop: "8px", paddingBottom: "8px"}}
                          />
                        </div>
                        <button
                          ref={buttonRef}
                          type={"submit"}
                          className={
                            "btn btn-danger waves-effect waves-light pakistan-color"
                          }
                          style={{
                            fontSize: "14.88px",
                            fontWeight: "400",
                            fontFamily: "Poppins",
                          }}
                        >
                          Update
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <Footer /> */} <CdaFooter />
    </>
  );
};
export default Profile;
