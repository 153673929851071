import "./App.css";
import Home from "./pages/Home/Home";
import { AuthProvider } from "./context/AuthContext";
import Map from "./pages/PropSureMap/Map";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import OnlinePropertyVerificationFAQs from "./pages/OnlinePropertyVerificationFAQs/OnlinePropertyVerificationFAQs";
import PropertyInspectionFAQs from "./pages/PropertyInspectionFAQs/PropertyInspectionFAQs";
import FeasibilityStudyFAQs from "./pages/FeasibilityStudyFAQs/FeasibilityStudyFAQs";
import ForensicAuditFAQs from "./pages/ForensicAuditFAQs/ForensicAuditFAQs";
import TechnicalAdvisoryFAQs from "./pages/TechnicalAdvisoryFAQs/TechnicalAdvisoryFAQs";
import DataServiceFAQs from "./pages/DataServiceFAQs/DataServiceFAQs";
import RequestHistories from "./pages/RequestHistories";
import Profile from "./pages/Profile";

import "./assets/main.css";
import NotFound from "./pages/NotFound";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import ScrollToTop from "./components/scrollTotop/ScrollToTop";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EmailVerification from "./pages/EmailVerification";
import Register from "./pages/register";
import ForgetPassword from "./pages/forget-password";
function App() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <AuthProvider>
          <Route component={Home} exact path="/" />
         {/* <Route
            component={OnlinePropertyVerificationFAQs}
            exact
            path="/OnlinePropertyVerificationFAQs"
          />
          <Route
            component={PropertyInspectionFAQs}
            exact
            path="/PropertyInspectionFAQs"
          />
          <Route
            component={FeasibilityStudyFAQs}
            exact
            path="/FeasibilityStudyFAQs"
          />
          <Route
            component={ForensicAuditFAQs}
            exact
            path="/ForensicAuditFAQs"
          />
          <Route
            component={TechnicalAdvisoryFAQs}
            exact
            path="/TechnicalAdvisoryFAQs"
          />*/}
          {/*<Route component={DataServiceFAQs} exact path="/DataServiceFAQs" />*/}
          <Route component={RequestHistories} exact path="/request-history" />
          <Route component={Profile} exact={true} path="/profile" />
          <Route component={Register} exact={true} path="/register" />
          <Route component={ForgetPassword} exact={true} path="/forget-password" />
          <Route component={EmailVerification} exact path="/email-verification/:code/:email" />
          <Route exact path="/map" component={Map} />
         {/* <Route
            exact
            path="/terms-and-conditions"
            component={TermsConditions}
          />
          <Route exact path="/privacy-policies" component={PrivacyPolicy} />*/}
        </AuthProvider>
        <Route component={NotFound} />
      </Switch>
      <ToastContainer autoClose={3000}/>
    </Router>
  );
}

export default App;
