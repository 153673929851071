import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

const CloseIcon =   (props) => {

    const {closePopup,setClosePopup,styles} = props
    return (
      <>
          <FontAwesomeIcon
              onClick={() => {
                  console.log('click')
                  console.log(closePopup,'click icon')
                  setClosePopup(!closePopup);
              }}
              style={styles}
              size="2x"
              icon={faTimes}
              color="#F35151"
          />
      </>
    );
}

export default CloseIcon;
