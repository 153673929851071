import React, { useEffect, useState, createContext } from "react";

import { getAuth, onAuthStateChanged } from "firebase/auth";
import {useSelector} from "react-redux";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [mapClicked, setMapClicked] = useState(null);
  const userData = useSelector((state => state.user))

    useEffect(()=>{
        let userLogin = (Object.keys(userData.user)).length> 0?userData.user:null
        setCurrentUser(userLogin)
    },[userData.user])
    useEffect(()=>{
        console.log(userData,'loading')
        setLoading(userData.loading)
    },[userData.loading])
  /*useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user;
        setCurrentUser(user);
        setLoading(false);
        // ...
      } else {
        setLoading(false);
        setCurrentUser(null);
      }
    });
  }, []);*/

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div>
          <p>Loading</p>
        </div>
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        loggedIn,
        setLoggedIn,
        mapClicked,
        setMapClicked,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
