import Auth from "../../Redux/Actions/Auth";
import ApiAuth from "../../API/ApiAuth";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { getPageTitle, GOOGLE_CAPTCHA_SITE_KEY } from "../../Redux/variables";
import Header from "../../components/header/Header";
import { Redirect } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import ScrollButton from "../../components/scrollButton/ScrollButton";
import React, { useContext, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { AuthContext } from "../../context/AuthContext";
import loginButton from "../../components/loginButton";
import LoginButton from "../../components/loginButton";
import ReCAPTCHA from "react-google-recaptcha";
import PageHeader from "../../components/PageHeader";
import Headerbar from "../../components/NavHeader";
import CdaHeader from "../../components/cdaHeader";
import CdaFooter from "../../components/cdaFooter";
const ForgetPassword = (props) => {
  const { currentUser } = useContext(AuthContext);
  const dispatch = useDispatch();
  const [forgetInVisible, setForgetInVisible] = useState(false);
  const [forgetEmail, setForgetEmail] = useState("");
  const forgetSubmit = useRef(null);
  const forgetSubmitSave = useRef(null);
  const [forgetSubmitInVisible, setForgetSubmitInVisible] = useState(false);
  const [forgetPassword, setForgetPassword] = useState("");
  const [forgetCode, setForgetCode] = useState("");

  const [googleCaptcha, setGoogleCaptcha] = useState("");
  const RecaptchaRef = useRef()

  const onGoogleChange = (value) => {
    setGoogleCaptcha(value);
  };
  const handleForgetSubmit = async (e) => {
    e.preventDefault();
    if (!googleCaptcha) {
      toast.warning("Google Captcha is required.");
      return;
    }
    forgetSubmit.current.innerHTML = "Please wait ...";
    forgetSubmit.current.disabled = true;
    dispatch(Auth.loader(true));
    await ApiAuth.forgetPassword({
      email: forgetEmail,
      googleCaptcha: googleCaptcha,
    })
      .then((response1) => {
        const { message, success } = response1.data;
        forgetSubmit.current.innerHTML = "Forget Password";
        forgetSubmit.current.disabled = false;
        if (success === true) {
          toast.success(message);
          setForgetInVisible(false);
          setForgetSubmitInVisible(true);
        } else {
          console.log(message, "error");
          toast.error(message);
        }
      })
      .catch((error) => {
        toast.error(error);
        forgetSubmit.current.innerHTML = "Forget Password";
        forgetSubmit.current.disabled = false;
        dispatch(Auth.loader(false));
      });
  };
  const handleForgetSubmitPost = async (e) => {
    e.preventDefault();
    forgetSubmitSave.current.innerHTML = "Please wait ...";
    forgetSubmitSave.current.disabled = true;
    dispatch(Auth.loader(true));
    await ApiAuth.forgetPasswordUpdate({
      email: forgetEmail,
      code: forgetCode,
      password: forgetPassword,
    })
      .then((response) => {
        console.log(response);
        const { message, success } = response;
        if (success === true) {
          forgetSubmitSave.current.innerHTML = "Save";
          forgetSubmitSave.current.disabled = false;
          toast.success(message);
          props.history.push("/");
          /* setForgetSubmitInVisible(false);*/
        } else {
          toast.error(message);
        }
      })
      .catch((error) => {
        toast.error(error);
        forgetSubmitSave.current.innerHTML = "Save";
        forgetSubmitSave.current.disabled = false;
        dispatch(Auth.loader(false));
      });
  };
  return (
    <>
      <Helmet>
        <title>Home {getPageTitle()}</title>
        <meta name="description" content="" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Home | ${getPageTitle()}`} />
        <meta property="og:description" content="" />
        <meta property="og:url" content="https://www.propsure.com.pk/" />
        <meta property="og:site_name" content="PropSure" />
        <meta property="article:publisher" content="" />
        <meta property="og:image" content="" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      {/* <Header state={props} /> */}
      <Headerbar />
      <CdaHeader />
      <PageHeader title="Forget Password" />
      {/* <ChatBox />
            <RealEstate />
            <Services />
            <PropMap />
            <WhyPropMap />
            <OurValues />*/}
      {currentUser && <Redirect to={"/map"} />}
      {/* <section className="second-section bg-red-line1" id="Services-section"> */}
      <div className="container services mb-5">
        <div className="row">
          <div className="col-lg-5 col-md-5 offset-4 login-form">
            {/* <h3 className={"text-center"}>
                {!forgetSubmitInVisible ? "Forget Password" : "Forget Password"}
              </h3> */}
            {!forgetSubmitInVisible && (
              <form onSubmit={handleForgetSubmit}>
                <div className="mb-3">
                  <input
                    value={forgetEmail}
                    onChange={(e) => {
                      setForgetEmail(e.target.value);
                    }}
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    id="email"
                    required
                  />
                </div>
                <div className="mt-3 d-grid">
                  <div className="captcha-wrapper1">
                    <ReCAPTCHA
                      size={"normal"}
                      sitekey={GOOGLE_CAPTCHA_SITE_KEY}
                      onChange={onGoogleChange}
                      ref={RecaptchaRef}
                    /><button
                    type="button"
                    onClick={()=>RecaptchaRef.current.reset()} 
                    style={{ color: "#739b21", border: 'none', backgroundColor: 'transparent', fontSize:'small', cursor: 'pointer'}}
                  >
                    Reset
                  </button>
                  </div>
                </div>
                <div className="mt-3 d-grid">
                  <button
                    className="btn btn-danger waves-effect waves-light pakistan-color"
                    type="submit"
                    ref={forgetSubmit}
                  >
                    Reset Password
                  </button>
                </div>
                <div className="mt-3 d-grid" style={{ textAlign: "center" }}>
                  {/* <p style={{ marginBottom: 0 }}>or</p> */}
                </div>
                <LoginButton {...props} />
              </form>
            )}
            {forgetSubmitInVisible && (
              <form onSubmit={handleForgetSubmitPost}>
                <div className="mb-3">
                  <input
                    value={forgetEmail}
                    onChange={(e) => {
                      setForgetEmail(e.target.value);
                    }}
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    value={forgetCode}
                    onChange={(e) => {
                      setForgetCode(e.target.value);
                    }}
                    type="text"
                    className="form-control"
                    placeholder="Code"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    value={forgetPassword}
                    onChange={(e) => {
                      setForgetPassword(e.target.value);
                    }}
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    required
                  />
                </div>

                <div className="mt-3 d-grid">
                  <button
                    className="btn btn-danger waves-effect waves-light pakistan-color"
                    type="submit"
                    ref={forgetSubmitSave}
                  >
                    Save
                  </button>
                </div>
                <div className="mt-3 d-grid" style={{ textAlign: "center" }}>
                  {/* <p style={{ marginBottom: 0 }}>or</p> */}
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
      {/* </section> */}
      <CdaFooter />
      {/* <Footer /> */}
      <ScrollButton />
    </>
  );
};

export default ForgetPassword;
