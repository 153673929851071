import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import API from "../../API";
import Sidebar from "../../components/Sidebar";
import { Dropdown } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { getPageTitle } from "../../Redux/variables";

import {
  findArrayIndex,
  PropsTimeFormat,
  TimeDifferenceCalculate,
  userLoginData,
} from "../../Helper";
import { setInterval } from "timers";
import ApiAuth from "../../API/ApiAuth";

const RequestListing = (props) => {
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [histories, setHistories] = useState([]);
  const [cancelButtonLoader, setCancelButtonLoader] = useState(false);
  const { currentUser } = useContext(AuthContext);
  useEffect(async () => {
    console.log(currentUser, "current Users");
    if (!currentUser) {
      props.history.push("/");
    } else {
      setLoader(true);
      await ApiAuth.getPlotRequests({ domain: "propsure", page: "1" })
        .then((response) => {
          console.log("res", response);
          if (response?.success === true) {
            let data = response?.data !== undefined ? response?.data?.data : [];
            setHistories(data);
          } else {
            setError("");
            setHistories([]);
          }
          setLoader(false);
        })
        .catch((error) => {
          setError(error);
          setLoader(false);
        });
    }
  }, []);
  const cancelRequestHandle = async (row) => {
    const { id } = row;
    setCancelButtonLoader(true);
    await ApiAuth.cancelPlotRequest(id, { domain: "propsure" })
      .then((response) => {
        // console.log(response, "Response");
        let index = findArrayIndex(histories, "id", id);
        let his = histories;
        setHistories([]);
        his.splice(index, 1);
        setCancelButtonLoader(false);
        setTimeout(() => {
          setHistories(his);
          setCancelButtonLoader(false);
        }, 50);
      })
      .catch((error) => {
        // console.log(error, "error");
      });
  };

  return (
    <>
      <div className="bg-red-color">
        <section className="second-section bg-red-line1" id="Services-section">
          <div className="container services">
            <div className="row">
              <div className="col-lg-3 col-md-3 leftSide">
                <Sidebar {...props} />
              </div>
              <div className="col-lg-9 col-md-9 rightSide">
                <div className="card">
                  <div
                    className="card-header"
                    style={{
                      fontSize: "21.88px",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                    }}
                  >
                    Request History
                  </div>
                  <div className="card-body">
                    {error && (
                      <div className="alert alert-danger mt-2 mb-2">
                        {error}
                      </div>
                    )}
                    <table className="table">
                      <thead>
                        <tr>
                          <th
                            style={{
                              fontSize: "16.88px",
                              fontWeight: "400",
                              fontFamily: "Poppins",
                            }}
                          >
                            #
                          </th>
                          <th
                            style={{
                              fontSize: "16.88px",
                              fontWeight: "400",
                              fontFamily: "Poppins",
                            }}
                          >
                            Name
                          </th>
                          {/* <th>Date</th>*/}

                          <th
                            style={{
                              fontSize: "16.88px",
                              fontWeight: "400",
                              fontFamily: "Poppins",
                            }}
                          >
                            Email
                          </th>
                          <th
                            style={{
                              fontSize: "16.88px",
                              fontWeight: "400",
                              fontFamily: "Poppins",
                            }}
                          >
                            Contact No.
                          </th>
                          {/* <th
                            style={{
                              fontSize: "16.88px",
                              fontWeight: "400",
                              fontFamily: "Poppins",
                            }}
                          >
                            Address
                          </th> */}
                          <th
                            style={{
                              fontSize: "16.88px",
                              fontWeight: "400",
                              fontFamily: "Poppins",
                            }}
                          >
                            Status
                          </th>
                          <th
                            style={{
                              fontSize: "16.88px",
                              fontWeight: "400",
                              fontFamily: "Poppins",
                            }}
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {loader && (
                          <tr>
                            <td className={"text-center"} colSpan={7}>
                              Loading Please wait ...
                            </td>
                          </tr>
                        )}
                        {!loader &&
                          histories.length > 0 &&
                          histories.map((row, index) => {
                            if (row?.status != "Cancelled") {
                              return (
                                <HistoryTr
                                  cancelRequest={cancelRequestHandle}
                                  buttonLoader={cancelButtonLoader}
                                  row={row}
                                  key={`histories-${index}`}
                                />
                              );
                            }
                          })}
                        {!loader && histories.length === 0 && (
                          <tr>
                            <td
                              style={{
                                fontSize: "16.88px",
                                fontWeight: "400",
                                fontFamily: "Poppins",
                              }}
                              className={"text-center"}
                              colSpan={7}
                            >
                              Records not founds
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default RequestListing;

const HistoryTr = (props) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const { buttonLoader } = props;
  const { row } = props;

  const cancelRequestHandle = (e, row) => {
    props.cancelRequest(row);
  };
  useEffect(() => {
    let isDisableCancel = TimeDifferenceCalculate(row.created_at);
    if (isDisableCancel > 1) {
      setIsDisabled(true);
    }
  }, []);

  return (
    <tr>
      <td>{`${row.id}`}</td>

      <td>{row?.fullname ?? "N/A"}</td>
      <td>{row?.email ?? "N/A"}</td>
      <td>{row?.contact_no ?? "N/A"}</td>
      {/* <td>{row?.customer_address ?? "N/A"}</td> */}
      {/*<td>{PropsTimeFormat(row.created_at)}</td>*/}
      {/* <td>
        {!isDisabled ? (
          <button
            disabled={buttonLoader}
            className="btn btn-danger"
            onClick={(e) => {
              cancelRequestHandle(e, row);
            }}
          >
            {buttonLoader ? "Please wait ..." : "Cancel"}
          </button>
        ) : (
          <></>
        )}
      </td> */}
      {/* <td>
        {row?.payment_status === 1 ? (
          <a href="javascript:void(0)" className="btn btn-success">
            Paid
          </a>
        ) : (
          <a
            href={`https://payment.propsure.co/?customer_request=${row?.id}`}
            target="_blank"
            className="btn btn-primary gredient-btn"
          >
            Pending
          </a>
        )}
      </td> */}
      <td>{row?.status ?? "N/A"}</td>
      <td>
        {row?.status === "Completed" && (
          <button
            className="btn"
            style={{
              background: "transparent",
              border: "1px solid #739B21",
              borderRadius: "8px",
            }}
          >
            <a
              href={row?.generated_file_url}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none", color: "#739B21" }}
            >
              Download
            </a>
          </button>
        )}
        {row?.status === "Rejected" && (
          <button className="btn btn-danger" disabled>
            Rejected
          </button>
        )}
        {row?.status !== "Completed" && row?.status !== "Rejected" && (
          <button className="btn btn-light text-dark p-0" disabled>
            In Progress
          </button>
        )}
      </td>
    </tr>
  );
};
