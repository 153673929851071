import {useState, useContext, useEffect, useRef} from "react";
import {
  MapContainer,
  TileLayer,
  LayersControl,
  Marker,
  ZoomControl,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "./map.css";
import LocateControl from "./components/LocateControl";
import WMSLayers from "./components/WMSLayers";
import WMSData from "./components/WMSData";
import LayerSwitch from "./components/LayerSwitch";
import MarkerIcon from "./components/MarkerIcon";
import LayerLegends from "./components/Legends/LayerLegends";
import InfoPanel from "./components/infopanel/InfoPanel";
import { getAuth, signOut } from "firebase/auth";
import ElasticSearch from "./components/elasticSearch/ElasticSearch";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { AuthContext } from "../../context/AuthContext";
import { mapbounds } from "../../config";
import { isMobile } from "../../utils";
import ApiAuth from "../../API/ApiAuth";
import {removeTokenUser} from "../../Redux/variables";
import Auth from "../../Redux/Actions/Auth";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import TagManager from "react-gtm-module";
import {GoogleView} from "../../Helper/GoogleView";
import {Helmet} from 'react-helmet'
import {getPageTitle} from '../../Redux/variables'



function Map(props) {
  const dispatch = useDispatch()
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  useEffect(()=>{
    GoogleView('Prop Map','/map')
  },[])
  try{
    const tagManagerArgs = {
      dataLayer: {
        userId: currentUser?.id,
        userProject: 'Users',
        page: 'map'
      },
      dataLayerName: 'PageDataLayer'
    }
    TagManager.dataLayer(tagManagerArgs)
  }catch (e) {

  }
  useEffect(()=>{
    redirectHome(currentUser)
  },[])
  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    redirectHome(currentUser)
  }, [currentUser]);
  const redirectHome = (currentUser) => {
    if (!currentUser) {
      props.history.push('/')
    }
  }

  const [open, setOpen] = useState(false);

  const [mapzoom, setmapzoom] = useState(6);
  const [comingSoon, setComingSoon] = useState(1);
  const [comingSoonData, setComingSoonData] = useState({});
  const [comingSoonData1, setComingSoonData1] = useState({});
  const [maphook, setMaphook] = useState(null);
  const [plotfilter, setplotfilter] = useState("all");
  const [centroid, setcentroid] = useState();
  const [plotData, setplotData] = useState("");
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });



  const comingSoonError = () => {
    setComingSoonData1({
      title:'',
      comingSoon:false,
      description:''
    });
    const {comingSoon,title,description} = comingSoonData
    setComingSoonData1({
      title:title,
      comingSoon:comingSoon,
      description:description
    });
   /* console.log(comingSoon,title,description,'dd')*/
  }

 /* useEffect(()=>{
    ApiAuth.comingSoon({}).then((response)=>{
      const {success,data} = response;
      if (success){
        setComingSoonData(data);
        setComingSoonData1(data);
        console.log(data,'response')
      }
    })
  },[])*/
  useEffect(()=>{
  },[maphook])
  useEffect(()=>{
    comingSoonError()
  },[comingSoon])


  const signout = () => {
    ApiAuth.logout({}).then((response)=>{
      removeTokenUser()
      dispatch(Auth.logout({}))
      toast.success(response.message)
      props.history.push('/')
      setCurrentUser(null);
    }).catch((error)=>{
      //dispatch(Auth.logout({}))
      toast.error(error)
      removeTokenUser()
      window.location.href = '/'
    })
    return

     /*    signOut(auth)
      .then(() => {
        setCurrentUser(null);
        setOpen(false);
        window.location.href = "/";
      })
      .catch((error) => {
        // An error happened.
      });*/
  };

  return (
    <>
         <Helmet>
         <title>CDA Digital Map {getPageTitle()}</title><meta name="description" content="" />
{/* <meta name="robots" content="index, follow" />
<meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
<meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" /> */}
<meta property="og:locale" content="en_US" />
<meta property="og:type" content="website" />
<meta property="og:title" content="Home | CDA" />
<meta property="og:description" content="" />
<meta property="og:url" content="/" />
<meta property="og:site_name" content="CDA" />
<meta property="article:publisher" content="" />
<meta property="og:image" content="" />
<meta name="twitter:card" content="summary_large_image" />
    </Helmet>
      {mapzoom >= 17 && (
        <LayerSwitch setplotfilter={setplotfilter} plotfilter={plotfilter} />
      )}

      {centroid && (
       <>
         <InfoPanel
             state={state}
             setState={setState}
             plotData={plotData}
             user={user}
         />
       </>
      )}

      <ElasticSearch
        user={user}
        setcentroid={setcentroid}
        map={maphook}
        open={state.left}
        plotData={plotData}
        setplotData={setplotData}
      />

      {mapzoom && <LayerLegends mapzoom={mapzoom} />}

      <MapContainer
        /*center={[30.3753, 69.3451]}*/
        center={[33.68895201115458, 73.11999708087887]}
        minZoom={10} /* min zoom 6*/
        maxZoom={22}
        zoom={11}
        zoomControl={false}
        animate={true}
        doubleClickZoom
        useFlyTo
        // maxBounds={mapbounds}
        whenCreated={(map) => {
          setMaphook(map);
          map.on("zoomend", () => {
            console.log(map.getZoom(),'map.getZoom()')
            setComingSoon(map.getZoom())
            setmapzoom(map.getZoom());
          });
          map.on("click", (e) => {
            setcentroid(e.latlng);
          });
        }}
      >
        {mapzoom > 16 && centroid && (
          <>
            <Marker position={centroid} icon={MarkerIcon} />

            <WMSData
              map={maphook}
              centroid={centroid}
              setState={setState}
              setplotData={setplotData}
            />
          </>
        )}

        <LayersControl position="topright">
          <LayersControl.BaseLayer checked name="Street View">
            <TileLayer
              attribution="mapbox"
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
             /* url="https://api.mapbox.com/styles/v1/araza410/ckum8p5yx0t9l17m08yt02x6t/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYXJhemE0MTAiLCJhIjoiY2t0M3htODRhMHlkZTJ2cGhrMGd1azhycSJ9.XrIlf9qKq3uT3J8r1vRRUw"*/
              maxZoom="22"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Gray Canvas">
            <TileLayer
              attribution="openstreetmaps"
              url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
              maxZoom="22"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Satellite View">
            <TileLayer
              attribution="googlemaps"
              url="https://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}"
              maxZoom="22"
            />
          </LayersControl.BaseLayer>
        </LayersControl>

        <div style={dropsownStyle}>
          <Dropdown className={"dropdown-items"}>
            <Dropdown.Toggle
              className="btn btn-light text-dark drop-shadow map-menu-dropdown-toggle"
              variant=""
              style={{width: '330px', 'text-align-last': 'start'}}
            >
              {isMobile() ? "Menu" : "Menu"}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{width: '330px'}}>
              <Dropdown.Item className={"nav-item"}>
                <Link className={"nav-link"} to={"/profile"}>
                  {"Profile"}
                </Link>
              </Dropdown.Item>
              <Dropdown.Item className={"nav-item"}>
                <Link className={"nav-link"} to={"/request-history"}>
                  {"Request History"}
                </Link>
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item className={"nav-item"}>
                <Link
                  onClick={signout}
                  className="nav-link"
                  aria-current="page"
                  to="/"
                  data-bs-toggle="modal"
                  data-bs-target="#SignIn"
                >
                  Sign out
                </Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <ZoomControl position="bottomright" />

        <LocateControl />

        <Link to="/">
          {/* <div className={'custom-footer-logo'} style={imageStyle}>
            <img
              className="img-fluid"
              src={require("../../assets/img/sop_logo.png").default}
            />
            <img
              className="img-fluid"
              src={require("../../assets/img/naphda.gif").default}
            />
            <img
              className="img-fluid"
              src={require("../../assets/img/cda-white-logo.gif").default}
            />


          </div> */}
        </Link>
        <WMSLayers mapzoom={mapzoom} plotfilter={plotfilter} />
      </MapContainer>

      <ComingSoonHtml userLogout={signout} comingSoonData={comingSoonData1} />

    </>
  );
}

const ComingSoonHtml = (props) => {
  const comingSoonData = props.comingSoonData
  const logout = () => {
    /*logoutRef.current.innerHTML = "Please wait ..."
    logoutRef.current.disabled = true*/
    props.userLogout();
  }
  return (
      <>
        { (comingSoonData.comingSoon === true) && (
            <div className="coming-soon">
              <div className="text-coming">
                {comingSoonData.title} <br/>
                <p style={{color:'red'}}>{comingSoonData.description}</p>
                <button className={'btn pakistan-color'} style={{fontSize:'12px',color:'#fff'}} onClick={logout} type={'button'}>Logout</button>
              </div>
            </div>
        )
        }
      </>
  )
}


const imageStyle = {
  display: "block",
  marginRight: "18px",
  position: "fixed",
  right: "0px",
  bottom: "0",
  zIndex: 900,
};

const dropsownStyle = {
  marginTop: isMobile() ? "80px" : "15px",
  width: "330px",
  display: "block",
  marginRight: isMobile() ? "45px" : "70px",
  position: "fixed",
  right: "0px",
  zIndex: 900,
};

export default Map;
