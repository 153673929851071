import {AUTH_LOGIN, AUTH_LOGOUT, SET_LOADER, UPDATE_AUTH_USER} from "../variables";

class Auth
{
    static  login (data)
    {
        return {
            type: AUTH_LOGIN,
            payload : data
        }
    }
    static updateUser(data)
    {
        return {
            type : UPDATE_AUTH_USER,
            payload :data
        }
    }

    static  logout (data)
    {
        console.log('action hit')
        return {
            type: AUTH_LOGOUT,
            payload : data
        }
    }

    static loader (data)
    {
        return {
            type:SET_LOADER,
            payload:data
        }
    }

}
export default Auth