import L from "leaflet";
import "leaflet.locatecontrol";
import { useMap } from "react-leaflet";
import { useEffect } from "react";

function LocateControl() {
  const map = useMap();

  useEffect(() => {
    const lc = L.control.locate({
      position: "bottomright",
      strings: {
        title: "Show me where I am, yo!",
      },
      locateOptions: {
        enableHighAccuracy: true,
      },
      flyTo: false,
      drawCircle: true,
      showCompass: true,
      icon: "fa fa-map-marker",
    });

    map.addControl(lc);
    // return () => map.removeControl(lc);
  }, [map]);

  return null;
}

export default LocateControl;
