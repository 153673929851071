import React from "react";
import { CDA_BASE_URL } from "../../config";
import "./style.css";

function CdaFooter() {
  return (
    <div className="footerContainer">
      <div style={{ margin: "auto", maxWidth: "1440px" }}>
        <div
          style={{
            display: "flex",
            gap: "70px",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
          className="footerMobile"
        >
          <div
            style={{ display: "flex", flexDirection: "column", color: "white" }}
          >
            <a
              href={CDA_BASE_URL}
              style={{
                textDecoration: "none",
                color: "white",
                fontSize: "12px",
              }}
            >
              <img src="footerLogo.svg" width="144px" height="76.71px" alt="" />
            </a>
            <p style={{ fontSize: "14px", marginTop: "16px", color: "white" }}>
              Together, let's shape a dynamic <br />
              and sustainable future for
              <br /> Islamabad, where every resident's <br />
              well-being is our priority.
            </p>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", color: "white" }}
          >
            <h2
              style={{
                fontSize: "14px",
                fontWeight: "600",
                marginBottom: "10px",
              }}
            >
              General Information
            </h2>
            <a
              style={{
                textDecoration: "none",
                color: "white",
              }}
              href={`${CDA_BASE_URL}cda-history`}
              className="space"
            >
              About CDA
            </a>
            <a
              style={{
                textDecoration: "none",
                color: "white",
              }}
              href="https://owo.cda.gov.pk/"
              className="space"
            >
              E-Services
            </a>
            <a
              style={{
                textDecoration: "none",
                color: "white",
              }}
              href={`${CDA_BASE_URL}news-releases`}
              className="space"
            >
              Media Center
            </a>
            <a
              style={{
                textDecoration: "none",
                color: "white",
              }}
              href={`${CDA_BASE_URL}contact-us`}
              className="space"
            >
              Contact Us
            </a>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", color: "white" }}
          >
            <h2
              style={{
                fontSize: "14px",
                fontWeight: "600",
                marginBottom: "10px",
              }}
            >
              Consumer Facilitation
            </h2>
            <a
              style={{
                textDecoration: "none",
                color: "white",
              }}
              href={`${CDA_BASE_URL}faqs`}
              className="space"
            >
              FAQs
            </a>
            <a
              style={{
                textDecoration: "none",
                color: "white",
              }}
              href={`${CDA_BASE_URL}career`}
              className="space"
            >
              Careers
            </a>
            <a
              style={{
                textDecoration: "none",
                color: "white",
              }}
              href="https://www.cda.gov.pk/eservices/registerComplaint.aspx"
              className="space"
            >
              Complaint Management
              <br /> System
            </a>
          </div>
          <div
            style={{
              display: "flex",

              flexDirection: "column",
              color: "white",
            }}
          >
            <h2
              style={{
                fontSize: "14px",
                fontWeight: "600",
                marginBottom: "10px",
              }}
            >
              Contact
            </h2>
            <div
              style={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
              }}
              className="space"
            >
              <img src="phone.svg" alt="" />
              <a
                style={{
                  textDecoration: "none",
                  color: "white",
                  fontSize: "12px",
                }}
                href="tel:051-9252614"
              >
                051-9252614
              </a>
            </div>
            <div
              style={{ display: "flex", gap: "16px", alignItems: "center" }}
              className="space"
            >
              <img src="emailIcon.svg" alt="" />
              <a
                style={{
                  textDecoration: "none",
                  color: "white",
                  fontSize: "12px",
                }}
                href="mailto:contactus@cda.gov.pk"
              >
                contactus@cda.gov.pk
              </a>
            </div>
            <a
              className="space"
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "none",
                color: "white",
                display: "flex",
                gap: "16px",
              }}
              href="https://www.google.com/maps/place/Capital+Development+Authority+Secretariat/@33.7023402,73.0752121,17z/data=!3m1!4b1!4m6!3m5!1s0x38dfbff2ea7d1f0d:0xbb2acabbc4bf1efb!8m2!3d33.7023358!4d73.077787!16s%2Fg%2F1tdzphlz?entry=ttu"
            >
              <img src="locationIcon.svg" alt="" width="20px" height="19px" />
              Secretary CDA
              <br /> Board, Block-V,
              <br /> Sector G-7/4,
              <br /> Islamabad
            </a>
          </div>
        </div>
        <div className="separator">
          <div
            style={{ width: "100%", height: "1px", background: "#F2F2F2" }}
          />
        </div>
        <div className="copyRightSection">
          <p style={{ fontSize: "14px", color: "white" }}>
            © 2023 CDA, All rights reserved.
          </p>
          <div className="socialLinks">
            <a
              href="https://twitter.com/CDAthecapital?ref_src=twsrc%5Etfw"
              target="_blank"
              rel="noreferrer"
            >
              <img src="fb-cda.svg" width="30px" height="30px" alt="" />
            </a>
            <div
              style={{
                height: "25px",
                color: "white",
                width: "0px",
                border: "0.5px solid",
              }}
            />
            <a
              href="http://www.facebook.com/cda.isb.pk"
              target="_blank"
              rel="noreferrer"
            >
              <img src="twitter-cda.svg" alt="" width="30px" height="30px" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CdaFooter;
