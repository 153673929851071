import React from "react";
import "./style.css";

export default function PageHeader({ title }) {
  return (
    <div
      className="headContainer"
      style={{
        backgroundColor: "#F2F2F2",
        backgroundImage: "url(illustrationPageHeader.svg)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <h1
        style={{
          fontWeight: "600",
          color: "#37474F",
          fontSize: "34px",
          lineHeight: "38.13px",
        }}
        className="heading-font"
      >
        {title}
      </h1>
    </div>
  );
}
