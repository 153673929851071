export const publicNoticesRoutes = [
  { name: "Public Notice", link: "public-notice" },
  { name: "Illegal Housing Schemes", link: "illegal-housing-schemes" },
  {
    name: "Balloting Announcement",
    link: "balloting-sector-c16-i15",
  },
  { name: "Auction Notices", link: "auction-notices" },
  // { name: "Open Auction of Plots", link: "open-auction-of-plots" },
];

export const procurementRoutes = [
  { name: "Tenders", link: "tenders" },
  { name: "Procurement Plans", link: "procurement-plans" },
];

export const dataReaserchRoutes = [
  { name: "CDA Projects", link: "cda-projects" },
  // { name: "Park Enclave", link: "/park-enclave" },
  // {
  //   name: "Commercial Building Project Status",
  //   link: "/commercial-building-project-status",
  // },
  { name: "CDA Budget", link: "cda-budget" },
  { name: "OPVS", link: "https://cdaopvs.graana.rocks/" },
];

export const wingDirectoratesRoutes = [
  { name: "Chairman Secretariat", link: "chairman-secretariat" },
  { name: "Administration Wing", link: "administration-wing" },
  { name: "Estate Wing", link: "estate-wing" },
  { name: "Engineering Wing", link: "engineering-wing" },
  { name: "Finance Wing", link: "finance-wing" },
  { name: "Planning & Design Wing", link: "planning-and-design-wing" },
  { name: "Environment Wing", link: "environment-wing" },
  { name: "Technology Wing", link: "tech-wing" },
];

export const CdaOrdinanceRelatedLinks = [
  { name: "Government of Pakistan", link: "https://pakistan.gov.pk/" },
  { name: "Cabinet Division", link: "https://www.cabinet.gov.pk/" },
  { name: "Ministry of Law", link: "https://molaw.gov.pk/" },
  { name: "CDA Law Directorate", link: "#" },
];

export const CdaOrdinanceRelatedInfo = [
  { name: " CDA By-Laws", link: "/by-laws-regulations" },
  { name: "CDA Contractors List", link: "/cda-contractors-list" },
];

export const auctionNoticesRelatedLinks = [
  { name: "PPRA Pakistan", link: "#" },
  { name: "Security Exchange Commission of Pakistan", link: "#" },
  { name: "Pakistan Engg Council", link: "#" },
];

export const AboutusRoutes = [
  { name: "Chairman CDA Message", link: "chairman-cda-message" },
  { name: "Boards Members", link: "board-members" },
  { name: "Board Meetings", link: "board-meetings" },
  { name: "CDA Training Academy", link: "cda-training-academy" },
  { name: "CDA History", link: "cda-history" },
];

export const AboutIslamabadRoutes = [
  { name: "About Islamabad", link: "about-islamabad" },
  { name: "Parks of ICT", link: "parks-of-ict" },
  { name: "Adventures", link: "adventures" },
  { name: "Hospitality", link: "hospitality" },
  { name: "Universities of ICT", link: "universities-of-ict" },
  { name: "CDA Model School", link: "cda-model-school" },
  { name: "Hospitals", link: "hospitals" },
  { name: "CDA Hospital", link: "cda-hospital" },
];

export const LegislationRoutes = [
  { name: "CDA Ordinance 1960", link: "cda-ordinance-1960" },
  { name: "Procedures", link: "procedures" },
  { name: "By-Laws & Regulations", link: "by-laws-regulations" },
];

export const EServicesRoutes = [
  { name: "CDA online", link: "https://owo.cda.gov.pk/" },
  {
    name: "Online Payments",
    link: "https://owo.cda.gov.pk/BillsPayment/BillPayment.aspx",
  },
  {
    name: "Complaint Management System",
    link: "https://www.cda.gov.pk/eservices/registerComplaint.aspx",
  },
];

export const mediaCenterRoutes = [
  { name: "News Releases", link: "news-releases" },
  { name: "Public Notices", link: "public-notices" },
  { name: "Housing Societies", link: "housing-societies" },
  { name: "Gallery", link: "gallery" },
  { name: "Procurement", link: "procurement" },
  { name: "FAQs", link: "faqs" },
  { name: "Forms & Applications", link: "forms-and-applications" },
];
