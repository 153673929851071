import L from "leaflet";

var MarkerIcon = new L.Icon({
  iconUrl: require("../../../assets/img/LocationIcon.png").default,
  shadowUrl: require("../../../assets/img/marker_shadow.png").default,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

export default MarkerIcon;
