import {isMobile} from "../../utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";

const LoginVideoModal = (props) => {
    const [isOpen,setIsOpen]  = useState(false)

    useEffect(()=>{
        setIsOpen(props.modalOpen)
    },[props.modalOpen])
    return (
        <>
            <div
                className="modal"
                style={{
                    display: isOpen ? "flex" : "none",
                    justifyContent: "center",
                    alignItems: "center",
                    // position: "-webkit-sticky" /* Safari */,
                    position: "fixed",
                    top: 0,
                    bottom: 0,
                    overflowY: "scroll",
                    width: "100%",
                    touchAction: "none",
                    backgroundColor: "#00000070",
                    zIndex: 10,
                }}
                // id="SignIn"
                tabIndex={100}
                aria-labelledby="SignIn"
                aria-hidden="false"
            >
                <div className="modal-dialog modal-xl">
                    <div
                        className="modal-content"
                        style={{ margin: "auto" }}
                    >
                        <div className="modal-body">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginBottom: 5,
                                }}
                            >
                                <FontAwesomeIcon
                                    onClick={() => {
                                        setIsOpen(!isOpen);
                                        props.closeModel(false)
                                    }}
                                    style={{
                                        // margin: "auto",
                                        cursor: "pointer",
                                    }}
                                    size="2x"
                                    icon={faTimes}
                                    color="#F35151"
                                />
                            </div>
                            <div className="container">
                                <div className="row">
                                    <video width="100%" height="100%" controls autoPlay={true} src={props.link}></video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginVideoModal