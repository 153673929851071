// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA7l6Vm9e3LVh05hxmYZgcQhjxFv95mHKA",
  authDomain: "propsure-app.firebaseapp.com",
  projectId: "propsure-app",
  storageBucket: "propsure-app.appspot.com",
  messagingSenderId: "77832951410",
  appId: "1:77832951410:web:8acca10b8f662bbb4b94eb",
  measurementId: "G-KHY0S2DRKG",
};
const firebase = initializeApp(firebaseConfig);
// firebase.initializeApp(firebaseConfig);
const db = getFirestore();

export { db, firebase };
